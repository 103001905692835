import React, { useState } from 'react'
import { Space } from 'antd'
import { HotArea } from '@/HOC'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    ConfigTitle,
    InputNumber,
    Select,
    BaseButton,
    ColorPicker,
    Radio,
} from '@/components'
import { InputNumber3x, Slider3x, InputWithImg } from '@/HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    deleteInsertPlugin,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const {
        intervalDuration,
        slideWidthDuration,
    } = extension
    const [currentIndex, setCurrentIndex] = useState(0)
    const curBanner = props.banners[currentIndex]
    const subProps = props.subProps[0]
    const subRectOptions = rectOptions.subRectOptions[0]
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="视频滑动"
                detail="固定水平排列，每次滑动切换一帧"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="滑动素材设置"
                        detail="每张图片支持单独编辑热区"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Select
                        chooseList={props.banners.map((v, i) => `第${i + 1}帧`)}
                        onClick={(idx) => {
                            setCurrentIndex(idx)
                        }}
                        currentIndex={currentIndex}
                        onDrop={(newIndexGrp) => {
                            const newList = []
                            newIndexGrp.forEach((i) => {
                                newList.push(props.banners[i])
                            })
                            updateCurrentPluginProps({
                                banners: newList,
                            })
                        }}
                        
                    />
                    <InputWithImg
                        labelContent="视频/图片链接"
                        defaultValue={curBanner.mediaUrl}
                        onChange={(e) => {
                            const list = [...props.banners]
                            list[currentIndex].mediaUrl = e
                            updateCurrentPluginProps({
                                banners: list,
                            })
                        }}
                    />
                    {
                        <Input
                            labelContent="跳转链接"
                            defaultValue={curBanner.jumpUrl}
                            onChange={(e) => {
                                const list = [...props.banners]
                                list[currentIndex].jumpUrl = e
                                updateCurrentPluginProps({
                                    banners: list,
                                })
                            }}
                        />
                    }

                    {
                        curBanner.mediaUrl && curBanner.mediaUrl.indexOf('.mp4') > -1 && (
                            <InputWithImg
                                labelContent="蒙层图链接"
                                defaultValue={curBanner.maskUrl}
                                onChange={(e) => {
                                    const list = [...props.banners]
                                    list[currentIndex].maskUrl = e
                                    updateCurrentPluginProps({
                                        banners: list,
                                    })
                                }}
                            />
                        )
                    }
                    {
                        curBanner.mediaUrl && curBanner.mediaUrl.indexOf('.mp4') > -1 && (
                            <InputWithImg
                                labelContent="封面图链接"
                                defaultValue={curBanner.posterUrl}
                                onChange={(e) => {
                                    const list = [...props.banners]
                                    list[currentIndex].posterUrl = e
                                    updateCurrentPluginProps({
                                        banners: list,
                                    })
                                }}
                            />
                        )
                    }
                    <Space>
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<DeleteFilled />}
                            content="删除本组"
                            onClick={() => {
                                const list = [...props.banners]
                                list.splice(currentIndex, 1)
                                if (list.length === 0) {
                                    deleteInsertPlugin(currentPlugin.insertId)
                                } else {
                                    setCurrentIndex(0)
                                    updateCurrentPluginProps({
                                        banners: list,
                                    })
                                }
                            }}
                        />
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<PlusOutlined />}
                            content="添加帧数"
                            onClick={() => {
                                const list = [...props.banners]
                                list.push(Object.assign({}, extension.struct))
                                updateCurrentPluginProps({
                                    banners: list
                                })
                            }}
                        />
                    </Space>
                </Space>
            </div>
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="滑动属性"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                props.imgWidth = val
                                updateCurrentPluginProps(props)
                            }}
                            defaultValue={props.imgWidth}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                props.imgHeight = val
                                updateCurrentPluginProps(props)
                            }}
                            defaultValue={props.imgHeight}
                        />
                    </Space>
                    <Space size={10} >
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.top = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.left = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.left}
                        />
                    </Space>
                    <Slider3x
                        title="滑动宽度"
                        suffix="px"
                        defaultValue={props.slideWidth}
                        max={slideWidthDuration[1]}
                        min={slideWidthDuration[0]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                slideWidth: v
                            })
                        }}
                    />
                    <Slider
                        title="展示时间"
                        suffix="秒"
                        defaultValue={props.interval}
                        max={intervalDuration[1]}
                        min={intervalDuration[0]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                interval: v
                            })
                        }}
                    />
                    <Space size={10}>
                        <Space direction="vertical" align="start">
                            <span style={{ fontSize: 12, color: '#999' }}>未激活图缩小比例:</span>
                            <InputNumber
                                labelContent=""
                                size="middle"
                                step={0.1}
                                style={{
                                    color: '#696969',
                                }}
                                onChange={(val) => {
                                    updateCurrentPluginProps({
                                        shrinkScale: val
                                    })
                                }}
                                defaultValue={props.shrinkScale}
                            />
                        </Space>
                        <Space direction="vertical" align="start">
                            <span style={{ fontSize: 12, color: '#999' }}>未激活图透明度:</span>
                            <InputNumber
                                labelContent=""
                                size="middle"
                                step={0.1}
                                style={{
                                    color: '#696969',
                                }}
                                onChange={(val) => {
                                    updateCurrentPluginProps({
                                        opacity: val
                                    })
                                }}
                                defaultValue={props.opacity}
                            />
                        </Space>
                    </Space>

                </Space>

            </div>
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="进度点设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="未激活宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    defaultWidth: val
                                }, 1)
                            }}
                            defaultValue={subProps.defaultWidth}
                        />
                        <InputNumber3x
                            labelContent="激活宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    activeWidth: val
                                }, 1)
                            }}
                            defaultValue={subProps.activeWidth}
                        />
                    </Space>
                    <InputNumber3x
                        labelContent="高"
                        size="middle"
                        style={{
                            color: '#696969',
                        }}
                        onChange={(val) => {
                            subRectOptions.height = val
                            changeCurrentPluginRect(subRectOptions, 1)
                        }}
                        defaultValue={subRectOptions.height}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions.top = val
                                changeCurrentPluginRect(subRectOptions, 1)
                            }}
                            defaultValue={subRectOptions.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions.left = val
                                changeCurrentPluginRect(subRectOptions, 1)
                            }}
                            defaultValue={subRectOptions.left}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="间距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    between: val
                                }, 1)
                            }}
                            defaultValue={subProps.between}
                        />
                        <InputNumber3x
                            labelContent="圆角"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    radius: val
                                }, 1)
                            }}
                            defaultValue={subProps.radius}
                        />
                    </Space>
                    <ColorPicker
                        title="默认颜色"
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                defaultColor: v
                            }, 1)
                        }}
                        defaultValue={subProps.defaultColor}
                    />
                    <ColorPicker
                        title="激活颜色"
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                activeColor: v
                            }, 1)
                        }}
                        defaultValue={subProps.activeColor}
                    />
                    <Radio
                        title="进度条动画"
                        defaultValue={props.type}
                        radioList={['默认', '填充']}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                type: v,
                            })
                        }}
                    />
                </Space>
            </div>
        </div>
    )
}