import Comp from "./comp";
import Desc from "./desc";
import Preview from "./preview";

const defaultRectOptions = {
  width: 375,
  height: 667,
  left: 0,
  top: 0,
  rotate: 0,
  subRectOptions: [],
};

const defaultDrawOptions = {
  resize: true,
  drag: true,
  rotate: false,
  keepRatio: false,
  renderDirections: ["nw", "n", "ne", "w", "e", "sw", "s", "se"],
  subOptions: [],
};

const plugin = {
  name: "上层飞入图",
  type: "TopBannerFly-image",
  render: () => <></>,
  board: Desc,
  preview: Preview,
  subPlugins: [Comp, Comp, Comp, Comp],
  props: {
    flyImageList: [
      {
        imageUrl1:
          "//img13.360buyimg.com/zx/jfs/t1/41662/34/22705/9784/6695d931Fb6e79f1a/f488499fcaf1a26b.png",
        clickUrl1: "",
        flyDirection1: "左",
        flyDistance1: 300,
        flyTime1: 2,
        hotArea1: [],
        imageUrl2:
          "//img30.360buyimg.com/zx/jfs/t1/223161/20/36738/4345/6695d927F5f78ae6b/703a16f61dc846da.png",
        clickUrl2: "",
        flyDirection2: "右",
        flyDistance2: 300,
        flyTime2: 2,
        hotArea2: [],
        imageUrl3:
          "//img14.360buyimg.com/zx/jfs/t1/224395/35/19457/3485/6695d927F4bc63f54/a589d7a5a56792ed.png",
        clickUrl3: "",
        flyDirection3: "右",
        flyDistance3: 300,
        flyTime3: 2,
        hotArea3: [],
        imageUrl4:
          "//img11.360buyimg.com/zx/jfs/t1/14361/30/21466/8018/6695d931Fc97fd9ee/6f79249c0276f3a5.png",
        clickUrl4: "",
        flyDirection4: "右",
        flyDistance4: 300,
        flyTime4: 2,
        hotArea4: [],
      },
    ],
    subProps: [],
  },
  extension: {
    struct: {
      imageUrl1:
        "//img12.360buyimg.com/zx/jfs/t1/235834/20/21951/11989/6695d931F7a699519/370e475d126ad6bb.png",
      clickUrl1: "",
      flyDirection1: "左",
      flyDistance1: 300,
      flyTime1: 2,
      imageUrl2:
        "//img14.360buyimg.com/zx/jfs/t1/20488/9/20612/9478/6695d931Fc9a531e9/cede84dd132f1cab.png",
      flyDirection2: "右",
      flyDistance2: 300,
      flyTime2: 2,
      imageUrl3:
      "//img14.360buyimg.com/zx/jfs/t1/245415/33/15309/4273/6695d927F5b09858a/decaa9f9594efaa5.png",
      clickUrl3: "",
      flyDirection3: "右",
      flyDistance3: 300,
      flyTime3: 3,
      imageUrl4:
      "//img12.360buyimg.com/zx/jfs/t1/235834/20/21951/11989/6695d931F7a699519/370e475d126ad6bb.png",
      clickUrl4: "",
      flyDirection4: "右",
      flyDistance4: 300,
      flyTime4: 3,
    },
    flyDistanceRange: [0, 500],
    flyTimeRange: [0, 10],
  },
  rectOptions: Object.assign(defaultRectOptions, {
    subRectOptions: [
      {
        width: 150,
        height: 50,
        top: 16,
        left: 8,
      },
      {
        width: 150,
        height: 50,
        top: 160,
        left: 100,
      },
      {
        width: 150,
        height: 50,
        top: 160,
        left: 100,
      },
      {
        width: 150,
        height: 50,
        top: 160,
        left: 100,
      },
    ],
  }),
  drawOptions: Object.assign(defaultDrawOptions, {
    subOptions: [
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
    ],
  }),
};

export default plugin;
