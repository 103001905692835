import React, { useRef, useCallback, useEffect, useState } from 'react'

export default function AlertCompPreview({
    props,
    rectOptions,
    zIndex = 0,
}) {
    const timer = useRef(null)
    let {
        width,
        height,
        left,
        top,
        subRectOptions,
    } = rectOptions
    width = (width)
    height = (height)
    left = (left)
    top = (top)
    const { mediaUrl, jumpUrl, animateTime, showTime, subProps, type } = props
    const subProp1 = subProps[0]
    const subProp2 = subProps[1]
    const rect1 = subRectOptions[0]
    const rect2 = subRectOptions[1]
    const defaultStyle = {
        opacity: type === 2 ? 0 : 1,
    }
    const activeStyle = {
        opacity: 1,
    }
    const [wrapStyle, setWrapStyle] = useState(defaultStyle)
    const [countdown, setCountDown] = useState(animateTime + showTime)
    const [showAlert, setShowAlert] = useState(true)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setWrapStyle(activeStyle)
        timer.current = setInterval(() => {
            setCountDown((state) => {
                if (state === 0) {
                    setWrapStyle(defaultStyle)
                    setIsOpen(true)
                    clearInterval(timer.current)
                    setTimeout(() => {
                        setShowAlert(false)
                    }, animateTime * 1000)
                    return 0
                } else {
                    return state - 1
                }
            })
        }, 1000)
        return () => {
            clearInterval(timer.current)
        }
    }, [])
    return (
        <>
            {
                showAlert && <div
                    style={Object.assign({
                        width,
                        height,
                        left,
                        top,
                        zIndex,
                        // opacity: type === 2 ? 0 : 1,
                        // transform: `translateY(${type === 1 ? -height - top : 0}px) scale(${type === 0 ? 0 : 1})`,
                        position: 'absolute',
                        transition: `${animateTime}s`,
                    }, wrapStyle)}
                >
                    <div
                        style={{
                            position: 'absolute',
                            width: width / 2,
                            height,
                            overflow: 'hidden',
                            left: 0,
                            top: 0,
                            transition: `${animateTime}s`,
                            transform: `translateX(${isOpen === true ? -100 : 0}%)`
                        }}
                        onClick={() => {
                            window.open(jumpUrl)
                        }}
                    >
                        {
                            mediaUrl.indexOf('.mp4') > -1 ? (
                                <video
                                    src={mediaUrl}
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        width,
                                        height,
                                    }}
                                    autoPlay
                                    muted
                                />
                            ) : <img
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    width,
                                    height,
                                }}
                                src={mediaUrl}
                            />
                        }
                    </div>
                    {
                        // 22222222
                    }
                    <div
                        style={{
                            position: 'absolute',
                            width: width / 2,
                            height,
                            overflow: 'hidden',
                            left: width / 2,
                            top: 0,
                            transition: `${animateTime}s`,
                            transform: `translateX(${isOpen === true ? 100 : 0}%)`
                        }}
                        onClick={() => {
                            window.open(jumpUrl)
                        }}
                    >
                        {
                            mediaUrl.indexOf('.mp4') > -1 ? (
                                <video
                                    src={mediaUrl}
                                    style={{
                                        position: 'absolute',
                                        left: width / 2,
                                        top: 0,
                                        width,
                                        height,
                                    }}
                                    autoPlay
                                    muted
                                />
                            ) : <img
                                style={{
                                    position: 'absolute',
                                    left: -width / 2,
                                    top: 0,
                                    width,
                                    height,
                                }}
                                src={mediaUrl}
                            />
                        }
                    </div>

                    {
                        isOpen === false && <img
                            style={{
                                width: (rect1.width),
                                height: (rect1.height),
                                left: (rect1.left - left),
                                top: (rect1.top - top),
                                position: 'absolute',
                            }}
                            onClick={() => {
                                clearInterval(timer.current)
                                setWrapStyle(defaultStyle)
                                setIsOpen(true)
                                setTimeout(() => {
                                    setShowAlert(false)
                                }, animateTime * 1000)
                            }}
                            src={subProp1.closeImgUrl}
                            alt=""
                        />
                    }

                    {
                        isOpen === false && <div
                            style={{
                                position: 'absolute',
                                left: (rect2.left) - left,
                                top: (rect2.top) - top,
                                fontSize: subProp2.fontSize,
                                fontWeight: subProp2.fontWeight,
                                color: subProp2.color,
                            }}
                        >
                            {countdown}
                        </div>
                    }

                </div>
            }
        </>

    )
}