//已修改：点击切换方向
import React, {  useEffect, useState } from 'react'

export default function ({
    rectOptions,
    props,
    zIndex = 0,
    currentIndex = 0,
    onClickNav = () => {},
}) {
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        if (activeIndex === currentIndex) return
        setActiveIndex(currentIndex)
    }, [currentIndex])

    return (
        <div
            style={{
                width: props.countWidth,
                height: props.navHeight,
                position: 'absolute',
                zIndex,
                top: rectOptions.top,
                left: rectOptions.left,
            }}
        >
            <div style={{
                width: rectOptions.width,
                height: props.navHeight,
                display: "flex",
                flexDirection: props.direction === 1 ? 'row' : 'column',
                flexWrap: 'wrap'
            }}>
                {
                    props && props.navList.length > 0 ? props.navList.map((item, index) => {
                        return <div key={index} style={{
                            marginLeft: index == 0 ? '' : props.between,
                            width: props.navWidth,
                            height: props.navHeight,
                        }} onClick={(e) => {
                            setActiveIndex(index)
                            onClickNav(index)
                        }}>
                            <img src={index == activeIndex ? item.activeUrl : item.defaultUrl} style={{
                                width: props.navWidth,
                                height: props.navHeight
                            }} alt="" />
                        </div>
                    }) : null
                }
            </div>
        </div>
    )
}