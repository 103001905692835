import React, { useState } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import '@/plugins/desc.less'
import {
    ConfigTitle,
    Select,
    BaseButton,
} from '@/components'
import { InputNumber3x, InputWithImg,Slider3x } from '@/HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    deleteInsertPlugin,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const [currentIndex, setCurrentIndex] = useState(0)
    const curNav = props.navList[currentIndex]
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="换行锚点"
                detail="锚点分默认、激活两种状态图片，点击切换状态和轮播图"
            />
            <div className="desc-board">

                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="锚点素材设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Select
                        chooseList={props.navList.map((v, i) => `第${i + 1}组`)}
                        onClick={(idx) => {
                            setCurrentIndex(idx)
                        }}
                        currentIndex={currentIndex}
                        onDrop={(newIndexGrp) => {
                            const newList = []
                            newIndexGrp.forEach((i) => {
                                newList.push(props.navList[i])
                            })
                            updateCurrentPluginProps({
                                navList: newList,
                            })
                        }}
                    />
                    <InputWithImg
                        labelContent="默认图片链接"
                        defaultValue={curNav.defaultUrl}
                        onChange={(e) => {
                            const list = [...props.navList]
                            list[currentIndex].defaultUrl = e
                            updateCurrentPluginProps({
                                navList: list,
                            })
                        }}
                    />
                    <InputWithImg
                        labelContent="激活图片链接"
                        defaultValue={curNav.activeUrl}
                        onChange={(e) => {
                            const list = [...props.navList]
                            list[currentIndex].activeUrl = e
                            updateCurrentPluginProps({
                                navList: list,
                            })
                        }}
                    />
                    <Space>
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<DeleteFilled />}
                            content="删除锚点"
                            onClick={() => {
                                const list = [...props.navList]
                                list.splice(currentIndex, 1)
                                if (list.length === 0) {
                                    deleteInsertPlugin(currentPlugin.insertId)
                                } else {
                                    setCurrentIndex(0)
                                    updateCurrentPluginProps({
                                        navList: list,
                                    })
                                }
                            }}
                        />
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<PlusOutlined />}
                            content="添加锚点"
                            onClick={() => {
                                const list = [...props.navList]
                                list.push(Object.assign({}, extension.struct))
                                updateCurrentPluginProps({
                                    navList: list
                                })
                            }}
                        />
                    </Space>

                </Space>
                <div className="desc-board">
                    <Space
                        direction="vertical"
                        size={10}
                    >
                        <ConfigTitle
                            title="锚点设置"
                            style={{
                                height: 21,
                            }}
                            titleSize={14}
                            detailSize={0}
                        />
                        <Space size={10}>
                            <InputNumber3x
                                labelContent="宽"
                                size="middle"
                                style={{
                                    color: '#696969',
                                }}
                                onChange={(val) => {
                                    updateCurrentPluginProps({
                                        navWidth: val
                                    })
                                }}
                                defaultValue={props.navWidth}
                            />
                            <InputNumber3x
                                labelContent="高"
                                size="middle"
                                style={{
                                    color: '#696969',
                                }}
                                onChange={(val) => {
                                    updateCurrentPluginProps({
                                        navHeight: val
                                    })
                                }}
                                defaultValue={props.navHeight}
                            />
                        </Space>
                        <Space size={10}>
                            <InputNumber3x
                                labelContent="上边距"
                                size="middle"
                                style={{
                                    color: '#696969',
                                }}
                                onChange={(val) => {
                                    changeCurrentPluginRect({
                                        top: val
                                    })
                                }}
                                defaultValue={rectOptions.top}
                            />
                            <InputNumber3x
                                labelContent="左边距"
                                size="middle"
                                style={{
                                    color: '#696969',
                                }}
                                onChange={(val) => {
                                    changeCurrentPluginRect({
                                        left: val
                                    })
                                }}
                                defaultValue={rectOptions.left}
                            />
                        </Space>
                        <InputNumber3x
                            labelContent="间距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    between: val
                                })
                            }}
                            defaultValue={props.between}
                        />
                        <Slider3x
                            title="滑动宽度"
                            suffix="px"
                            defaultValue={rectOptions.width}
                            max={extension?.scrollWidthDuration[1]}
                            min={extension?.scrollWidthDuration[0]}
                            onChange={(v) => {
                                changeCurrentPluginRect({
                                    width: v,
                                })
                            }}
                        />
                    </Space>
                </div>
            </div>
        </div>
    )
}
