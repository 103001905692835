import Comp from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '店铺通知',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    type: 'shop-inform',
    props: {
        textList: [
            {
                content: '文本'
            }
        ],
        fontSize: 16,
        fontWeight: 400,
        color: '#000',
        interval: 3,
        subProps: [
        ],
    },
    extension: {
        fontSizeDuration: [0, 100],
        fontWeightDuration: [100, 900],
        intervalDuration: [0, 10],
        struct: {
            content: '文本'
        }
    },
    rectOptions: {
        width: 375,
        height: 100,
        left: 0,
        top: 30,
        rotate: 0,
        subRectOptions: []
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: false,
        subOptions: [],
    },
}

export default Plugin