import React from 'react'

export default function({ rectOptions, props }) {
    const { width, height, left, top } = rectOptions
    const { fontSize, fontWeight, color, text } = props
    return (
        <div style={{
            width,
            height,
            transform: `translate(${left}px, ${top}px)`,
            overflow: 'hidden',
        }}>
            <div
                style={{
                    fontSize,
                    fontWeight,
                    color,
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap'
                }}
            >
                {text}
            </div>
        </div>
    )
}