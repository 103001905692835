import React from 'react'

export default function Comp({
    rectOptions,
    props,
}) {
    const { width, height, top, left } = rectOptions
    const { textList, fontSize, fontWeight, color } = props
    return <div
        style={{
            width,
            height,
            transform: `translate(${left}px, ${top}px)`,
            position: 'absolute',
        }}
    >
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontSize,
                fontWeight,
                color,
                backgroundColor: 'yellow',
            }}
        >
            {textList[0].content}
        </div>
        
    </div>
}