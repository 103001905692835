import Comp, { CloseBtn, CountDown, ScaleAlert } from './comp'
import Preview from './preview'
import Desc from './desc'

const Plugin = {
    name: '缩放弹窗组件',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [CloseBtn, CountDown, ScaleAlert],
    type: 'scale-aler-comp',
    props: {
        
        imageUrl: '//img11.360buyimg.com/zx/jfs/t1/154053/28/3631/358762/5f97abbaE29bd2ee8/ca99e56fd3cf4e4b.png',
        jumpUrl: '',
        // type: 2, // '中心弹出', '上方滑入', '渐现'// 固定为渐现
        animateTime: 5,
        showTime: 3,
        subProps: [{
            closeImgUrl: '//img30.360buyimg.com/zx/jfs/t1/123463/4/3969/748/5ed88322Ed2f81b91/1dc1908ada1eb0b7.png',
        }, {
            // time: 1, // animateTime + showTime
            fontSize: 32,
            fontWeight: 500,
            color: '#ffffff',
        }, {
            scaleAnimateTime: 2,
            animateRotate: 20,
            animateRate: 3,
        }]
    },
    extension: {
        animateTimeDuration: [1, 10],
        showTimeDuration: [1, 10],
        fontSizeDuration: [0, 200],
        fontWeightDuration: [100, 900],
        scaleAnimateTimeDuration: [0, 10],
        animateRotateDuration: [0, 90],
        animateRateDuration: [0, 10]
    },
    rectOptions: {
        width: 375,
        height: 400,
        left: 8,
        top: 16,
        rotate: 0,
        subRectOptions: [
            {
                width: 50,
                height: 50,
                left: 312,
                top: 15,
                rotate: 0,
            },
            {
                left: 6,
                top: 16,
                rotate: 0,
            },
            {
                width: 200,
                height: 200,
                top: 300,
                right: 300,
            }
        ]
    },
    drawOptions: {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
        subOptions: [{
            resize: true,
            drag: true,
            rotate: false,
            keepRatio: true,
        }, {
            resize: false,
            drag: true,
            rotate: false,
            keepRatio: true,
        }, {
            resize: true,
            drag: true,
            rotate: false,
            keepRatio: true,
        }]
    },
}

export default Plugin