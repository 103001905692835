import React, { useRef, useCallback, useEffect, useState } from 'react'


export default function AlertCompPreview({
    props,
    rectOptions,
    zIndex = 0,
    track = () => 0,
    floorData,
}) {
    const timer = useRef(null)
    const {
        width,
        height,
        left,
        top,
        subRectOptions,
    } = rectOptions
    const { imageUrl, jumpUrl, animateTime, showTime, subProps, type } = props
    const subProp1 = subProps[0]
    const subProp2 = subProps[1]
    const subProp3 = subProps[2]
    const rect1 = subRectOptions[0]
    const rect2 = subRectOptions[1]
    const rect3 = subRectOptions[2]
    const finalStyle = {
        width: rect3.width,
        height: rect3.height,
        opacity: 1,
        left: rect3.left,
        top: rect3.top,
        // transform: `translate(${rpx1x(rect3.left)}px, ${rpx1x(rect3.top)}px)`,
    }
    const defaultStyle = {
        opacity: 0,
    }
    const activeStyle = {
        opacity: 1,
    }
    const [wrapStyle, setWrapStyle] = useState(defaultStyle)
    const [countdown, setCountDown] = useState(animateTime + showTime)
    const wrapRef = useRef(null)
    const flag = useRef(false)

    const startAnimate = () => {
        setInterval(() => {
            flag.current = !flag.current
            wrapRef.current.style.transform = `rotateZ(${flag.current === false ? -subProp3.animateRotate : subProp3.animateRotate}deg)`
        }, subProp3.animateRate * 1000)
    }

    useEffect(() => {
        setWrapStyle(activeStyle)
        timer.current = setInterval(() => {
            setCountDown((state) => {
                if (state === 0) {
                    wrapRef.current.style.transition = `${subProp3.scaleAnimateTime}s`
                    setWrapStyle(finalStyle)
                    clearInterval(timer.current)
                    setTimeout(() => {
                        wrapRef.current.style.transition = `${subProp3.animateRate}s`
                        wrapRef.current.style.transform = `rotateZ(${flag.current === false ? -subProp3.animateRotate : subProp3.animateRotate}deg)`
                        startAnimate()
                    }, subProp3.scaleAnimateTime * 1000)
                    return 0
                } else {
                    return state - 1
                }
            })
        }, 1000)
        return () => {
            clearInterval(timer.current)
        }
    }, [])
    return (

        <div
            ref={wrapRef}
            style={Object.assign({
                width: width,
                height: height,
                left: left,
                top: top,
                zIndex,
                opacity: 0,
                position: 'absolute',
                transition: `${animateTime}s`
            }, wrapStyle)}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                }}
                onClick={() => {
                    window.open(jumpUrl)
                }}
            >
                <img
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    src={imageUrl}
                />
            </div>


            {
                countdown > 0 && <img
                    style={{
                        width: rect1.width,
                        height: rect1.height,
                        left: rect1.left - left,
                        top: rect1.top - top,
                        position: 'absolute',
                    }}
                    onClick={() => {
                        wrapRef.current.style.transition = `${subProp3.scaleAnimateTime}s`
                        setWrapStyle(finalStyle)
                        setCountDown(-1)
                        clearInterval(timer.current)
                        setTimeout(() => {
                            wrapRef.current.style.transition = `${subProp3.animateRate}s`
                            wrapRef.current.style.transform = `rotateZ(${flag.current === false ? -subProp3.animateRotate : subProp3.animateRotate}deg)`
                            startAnimate()
                        }, subProp3.scaleAnimateTime * 1000)
                    }}
                    src={subProp1.closeImgUrl}
                    alt=""
                />
            }
            {
                countdown > 0 && <div
                    style={{
                        position: 'absolute',
                        left: rect2.left - left,
                        top: rect2.top - top,
                        fontSize: subProp2.fontSize,
                        fontWeight: subProp2.fontWeight,
                        color: subProp2.color
                    }}
                >
                    {countdown}
                </div>
            }

        </div>
    )
}