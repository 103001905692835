import React, { useState, useRef, useImperativeHandle } from 'react'

export default React.forwardRef(function Shade({
    list,
    style = {},
    onClick = () => {}
}, ref) {
    const animateDuration = 300
    // const rotateTime = useRef(0)
    const shadeChange = (timeout) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                setActiveIndex(activeIndex + 1 >= list.length ? 0 : activeIndex + 1)
            }, timeout)
        })
    }
    const [activeIndex, setActiveIndex] = useState(0)
    useImperativeHandle(ref, () => ({
        shadeChange
    }))
    return <div
        style={Object.assign({
            position: 'relative',
        }, style)}
        onClick={() => {
            onClick(activeIndex)
        }}
    >
        {
            list.map((item, index) => {
                return <div
                    key={index}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: activeIndex === index ? 1 : 0,
                        width: '100%',
                        height: '100%',
                        transition: `${animateDuration}ms linear`,
                        opacity: index === activeIndex ? 1 : 0,
                    }}
                >
                    <img style={{ width: '100%', height: '100%' }} src={item.image} alt="" />
                </div>
            })
        }


    </div>
})