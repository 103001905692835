import React, { useState, useEffect } from 'react'

export default function ArrowPreview({
    baseConfig,
    rectOptions,
    props,
    zIndex = 0,
    onClickLeft = () => {},
    onClickRight = () => {},
}) {
    const { subRectOptions } = rectOptions
    const { subProps, animateDist, animateTime } = props
    const [leftMoveDist, setLeftMoveDist] = useState(0)
    useEffect(() => {
        setLeftMoveDist(animateDist)
    }, [])
    return (
        <>
            {
                subProps.map((p, i) => {
                    const { left, height, width, top } = subRectOptions[i]
                    return (
                        <img
                            key={i}
                            style={{
                                position: 'absolute',
                                left,
                                top,
                                width,
                                height,
                                zIndex,
                                transform: `translateX(${i === 0 ? -leftMoveDist : leftMoveDist}px)`,
                                transition: `transform ${animateTime}s ease-in-out`
                            }}
                            onClick={() => {
                                // TODO 超级反逻辑的代码！建议有时间重新修改！！！
                                if (i === 0) {
                                    onClickRight()
                                } else {
                                    onClickLeft()
                                }
                            }}
                            onTransitionEnd={() => {
                                setLeftMoveDist(-leftMoveDist)
                            }}
                            src={p.imageUrl}
                            alt=""
                        />
                    )
                })
            }
        </>
    )
}