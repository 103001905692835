import React, { useMemo, useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react'

export default forwardRef(function Preview({
    baseConfig,
    rectOptions,
    props,
    zIndex = 0,
    onChange = () => { },
}, ref) {
    const { left, top, subRectOptions } = rectOptions
    const mouseDownPosX = useRef(0)
    const isMouseDown = useRef(false)
    const videoPlayDelayTimer = useRef(null)
    const isAnimating = useRef(false)
    const timer = useRef(null)
    const cloneNum = useMemo(() => 50, [])
    const { subProps, imgWidth, imgHeight, slideWidth, banners, shrinkScale, opacity, interval } = props
    const [currentIndex, setCurrentIndex] = useState(cloneNum / 2 * banners.length)
    const subProp = subProps[0]
    const subRect = subRectOptions[0]
    const { defaultWidth, activeWidth, between, radius, defaultColor, activeColor } = subProp
    const subLeft = subRect.left
    const subTop = subRect.top
    const subHeight = subRect.height
    const renderBanners = useMemo(() => {
        const result = []
        for (let i = 0; i < cloneNum; i++) {
            result.push(...banners)
        }
        return result
    }, [banners])
    const videoPitList = useRef(renderBanners.map(() => null))
    const autoScroll = useCallback(() => {
        timer.current = setInterval(() => {
            setCurrentIndex((state => {
                onChange((state + 1) % banners.length)
                return state + 1
            }))
        }, interval * 1000)
    }, [timer])
    const stopAutoScroll = useCallback(() => {
        clearInterval(timer.current)
    }, [timer])
    useEffect(() => {
        autoScroll()
        return stopAutoScroll
    }, [])
    useEffect(() => {
        clearTimeout(videoPlayDelayTimer.current)
        videoPlayDelayTimer.current = setTimeout(() => {
            videoPitList.current.forEach((videoElem, index) => {
                if (videoElem) {
                    videoElem.currentTime = 0
                    if (index === currentIndex) {
                        videoElem.play()
                    } else {
                        videoElem.pause()
                    }
                }
            })
        }, 400)
    }, [currentIndex])
    useImperativeHandle(ref, () => {
        return {
            singleLen: banners.length,
            currentIndex,
            setCurrentIndex: (index) => {
                stopAutoScroll()
                autoScroll()
                setCurrentIndex(index)
            },
        }
    })
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    top,
                    left,
                    zIndex,
                    width: slideWidth,
                    height: imgHeight,
                    display: 'flex',
                    overflow: 'hidden',
                }}
                onMouseDown={(e) => {
                    stopAutoScroll()
                    mouseDownPosX.current = e.clientX
                    isMouseDown.current = true
                }}
                onMouseUp={(e) => {
                    if (isMouseDown.current === false) return
                    if (e.clientX - mouseDownPosX.current > 5) {
                        onChange((currentIndex - 1) % banners.length)
                        setCurrentIndex(currentIndex - 1)
                    } else if (e.clientX - mouseDownPosX.current < -5) {
                        onChange((currentIndex + 1) % banners.length)
                        setCurrentIndex(currentIndex + 1)
                    }
                    isMouseDown.current = false
                    autoScroll()
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        display: 'flex',
                        transform: `translateX(-${currentIndex * imgWidth - slideWidth / 2 + imgWidth / 2}px)`,
                        transition: '300ms',
                        overflow: 'hidden',
                    }}
                >
                    {
                        renderBanners.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: imgWidth,
                                        height: imgHeight,
                                        position: 'relative',
                                        zIndex: currentIndex === index ? 10 : 0,
                                    }}
                                    onClick={() => {
                                        window.location.href = item.jumpUrl
                                    }}
                                >
                                    {
                                        item.mediaUrl.indexOf('.mp4') >= 0
                                            ? <div
                                                key={index}
                                                style={{
                                                    transform: currentIndex === index ? 'scale(1)' : `scale(${shrinkScale})`,
                                                    opacity: currentIndex === index ? 1 : opacity,
                                                    flexShrink: 0,
                                                    width: imgWidth,
                                                    height: imgHeight,
                                                    transition: `${300}ms`,
                                                }}
                                            >
                                                <video
                                                    src={item.mediaUrl}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                    loop
                                                    poster={item.posterUrl}
                                                    muted
                                                    ref={(ref) => {
                                                        videoPitList.current[index] = ref
                                                    }}
                                                ></video>
                                                <img
                                                    src={item.maskUrl}
                                                    alt=""
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                    }}
                                                    draggable={false}
                                                />
                                            </div>
                                            : <img
                                                key={index}
                                                onClick={() => {
                                                }}
                                                src={item.mediaUrl}
                                                style={{
                                                    width: imgWidth,
                                                    height: imgHeight,
                                                    transform: currentIndex === index ? 'scale(1)' : `scale(${shrinkScale})`,
                                                    opacity: currentIndex === index ? 1 : opacity,
                                                    transition: `${300}ms`,
                                                }}
                                                draggable={false}
                                            />
                                    }
                                    {/* <img
                                        onClick={() => {
                                        }}
                                        src={item.imageUrl}
                                        style={{
                                            width: imgWidth,
                                            height: imgHeight,
                                            transform: currentIndex === index ? 'scale(1)' : `scale(${shrinkScale})`,
                                            opacity: currentIndex === index ? 1 : opacity,

                                            transition: `${300}ms`,
                                        }}
                                        draggable={false}
                                    /> */}

                                </div>
                            )
                        })
                    }
                </div>

            </div>
            <div style={{
                display: 'flex',
                position: 'absolute',
                left: subLeft,
                top: subTop,
                height: subHeight,
                zIndex,
            }}>
                {
                    banners.map((b, index) => {
                        return (
                            <div
                                key={index + 'dot'}
                                style={{
                                    width: currentIndex % banners.length === index ? activeWidth : defaultWidth,
                                    height: subHeight,
                                    marginLeft: index === 0 ? 0 : between,
                                    backgroundColor: currentIndex % banners.length === index ? activeColor : defaultColor,
                                    borderRadius: radius,
                                }}
                            ></div>
                        )
                    })
                }
            </div>
        </>

    )
})