import React from 'react'
import schema from './schema.json'
import Comp from './comp'
import Desc from './desc'
import Preview from './preview'
const defaultRectOptions = {
    width: 375,
    height: 667,
    left: 0,
    top: 0,
    rotate: 0,
    subRectOptions: []
}
const defaultDrawOptions = {
    resize: true,
    drag: true,
    rotate: false,
    keepRatio: false,
    renderDirections: ["nw","n","ne","w","e","sw","s","se"],
    subOptions: [],
}
const { data, draw_ui, config_ui } = schema

let props = {}
let rectOptions = {}
let extension = {}
let drawOptions = {}

const { main_data } = data
Object.keys(main_data.properties).forEach((k, i) => {
    const item = main_data.properties[k]
    props[k] = item.value
    if (item.range) {
        extension[k + 'Range'] = item.range.split('-').map(n => isNaN(Number(n)) ? n : Number(n))
    }
})

Object.keys(main_data.rect).forEach((k, i) => {
    const item = main_data.rect[k]
    rectOptions[k] = item.value
})

props.origin_data = 'main_data'


props.subProps = []
rectOptions = Object.assign(defaultRectOptions, rectOptions)
drawOptions = Object.assign(defaultDrawOptions, draw_ui[0].draw_options)
const Plugin = {
    name: schema.title,
    type: schema.type,
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [],
    props,
    extension,
    rectOptions,
    drawOptions,
}

export default Plugin