import React from 'react'

export default function Comp({
    props,
    rectOptions,
    currentIndex
}) {
    const { subRectOptions } = rectOptions
    const { subProps } = props
    const { width, height, left, top } = subRectOptions[currentIndex]
    const { imageUrl, show } = subProps[currentIndex]
    return (
        <div
            style={{
                position: 'absolute',
                width,
                height,
                overflow: 'hidden',
                transform: `translate(${left}px, ${top}px)`
            }}
        >
            {
                show === true && <img
                src={imageUrl}
                alt=""
                style={{
                    width: '100%',
                    height: '100%',
                }}
            />
            }
            
        </div>
    )
}