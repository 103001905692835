import React, { useMemo } from 'react'
import  HotArea from "../../../components/HotArea"
import _ from 'lodash'
export default function Preview({
    rectOptions,
    props,
    zIndex = 0,
}, ) {
    const { imageUrl, hotArea } = props
    const { height } = rectOptions
    //const catchHotArea = useMemo(() => _.isEmpty(moduleUtil.getDataDefine(floorData, 'hotspotItemData#hotArea')) ? {data: []} : moduleUtil.getDataDefine(floorData, 'hotspotItemData#hotArea'), [])
    //跳转和热区价格
    return (
        <div
            style={{
                width: '100%',
                height: height,
            }}
        >
            <img style={{ width: '100%', height: '100%' }} src={imageUrl} alt="" />
            {hotArea.map((hot, hotIndex) => {
                return (
                    <HotArea hot={hot} key={hotIndex}
                    // catchPrice={catchHotArea.data[hotIndex] ? catchHotArea.data[hotIndex].price : ' '}
                    />
                )
            })}
        </div>
    )
}