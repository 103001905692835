import React, { useState } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    ConfigTitle,
    ColorPicker,
    Select,
    BaseButton,
} from '@/components'
import { InputNumber3x, InputWithImg } from '@/HOC'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    deleteInsertPlugin,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const { width, height, left, top } = rectOptions
    const { textList, fontSize, fontWeight, color, interval } = props
    const [currentIndex, setCurrentIndex] = useState(0)

    return (
        <div className="desc-wrap">
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="店铺通知"
                detail="多行文本垂直轮播切换"
            />
            <div className="desc-board">
                <Space direction="vertical" size={16}>
                    <Select
                        chooseList={textList.map((v, i) => `第${i + 1}组`)}
                        onClick={(idx) => {
                            setCurrentIndex(idx)
                        }}
                        currentIndex={currentIndex}
                        onDrop={(newIndexGrp) => {
                            const newList = []
                            newIndexGrp.forEach((i) => {
                                newList.push(textList[i])
                            })
                            updateCurrentPluginProps({
                                textList: newList,
                            })
                        }}
                    />
                    <Input
                        labelContent="切换文本"
                        defaultValue={textList[currentIndex].content}
                        onChange={(e) => {
                            const newList = [...textList]
                            newList[currentIndex].content = e
                            updateCurrentPluginProps({
                                textList: newList,
                            })
                        }}
                    />
                    <Space>
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<DeleteFilled />}
                            content="删除本组"
                            onClick={() => {
                                const list = [...textList]
                                list.splice(currentIndex, 1)
                                if (list.length === 0) {
                                    deleteInsertPlugin(currentPlugin.insertId)
                                } else {
                                    setCurrentIndex(0)
                                    updateCurrentPluginProps({
                                        textList: list,
                                    })
                                }
                            }}
                        />
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<PlusOutlined />}
                            content="添加帧数"
                            onClick={() => {
                                const list = [...textList]
                                list.push(Object.assign({}, extension.struct))
                                updateCurrentPluginProps({
                                    textList: list
                                })
                            }}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    width: val
                                })
                            }}
                            defaultValue={width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    height: val
                                })
                            }}
                            defaultValue={height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    top: val
                                })
                            }}
                            defaultValue={top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    left: val
                                })
                            }}
                            defaultValue={left}
                        />
                    </Space>
                    <Slider
                        suffix="px"
                        title="文字大小"
                        max={extension.fontSizeDuration[1]}
                        min={extension.fontSizeDuration[0]}
                        defaultValue={fontSize}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                fontSize: v
                            })
                        }}
                    />
                    <Slider
                        suffix=""
                        title="文字粗细"
                        step={100}
                        max={extension.fontWeightDuration[1]}
                        min={extension.fontWeightDuration[0]}
                        defaultValue={fontWeight}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                fontWeight: v
                            })
                        }}
                    />
                    <Slider
                        suffix=""
                        title="自动切换时间"
                        step={1}
                        max={extension.intervalDuration[1]}
                        min={extension.intervalDuration[0]}
                        defaultValue={interval}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                interval: v
                            })
                        }}
                    />
                    <ColorPicker
                        title="文字颜色"
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                color: v
                            })
                        }}
                        defaultValue={color}
                    />
                </Space>
            </div>
        </div>
    )
}