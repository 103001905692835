import React, { useRef, useCallback, useEffect, useImperativeHandle } from 'react'
// import { InOrOutViewObserver } from '@conecli/cone-render/components'

import Shade from "../../components/Shade"

export default React.forwardRef(function Preview({
    rectOptions,
    props,
    zIndex = 0,
    floorData,
}, ref) {
    const { top, left, width, height } = rectOptions
    const { flipImageList, interval, origin_data } = props
    const flipRef = useRef([])
    const timer = useRef(null)
    const isAnimating = useRef(false)

    const allFlipChange = (useLayout) => {
        if (isAnimating.current === true) return
        stopAuto()
        isAnimating.current = true
        flipRef.current.forEach((item, index) => {
            item.shadeChange(0)
        })
        setTimeout(() => {
            isAnimating.current = false
            startAuto()
        }, flipRef.current.length * 100)
    }

    const startAuto = useCallback(() => {
        timer.current = setInterval(() => {
            allFlipChange(true)
        }, interval * 1000)
    }, [timer.current])

    const stopAuto = useCallback(() => {
        clearInterval(timer.current)
    }, [])

    useEffect(() => {
        startAuto()
    }, [])

    useImperativeHandle(ref, () => ({
        allFlipChange,
    }))

    return (
        <div
            style={{
                width: width,
                height: height,
                position: 'absolute',
                left: left,
                top: top,
                display: 'flex',
                flexWrap: 'wrap',
                overflow: 'hidden',
            }}
        >
            {
                flipImageList.map((item, index) => {
                    return (
                        <Shade
                            ref={(ref) => (flipRef.current[index] = ref)}
                            list={[{
                                image: item.imageUrl1,
                            }, {
                                image: item.imageUrl2
                            }]}
                            onClick={(idx) => {
                                window.open(item[`clickUrl${idx + 1}`], floorData)
                                // handlePress(item[`clickUrl${idx + 1}`], floorData)
                            }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: item.imageWidth,
                                height: item.imageHeight,
                            }}
                        />
                    )
                })
            }
        </div>
    )
})