import React, { useState } from "react";
import { Space } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import Comp from "./comp";
import "@/plugins/desc.less";
import {
  Input,
  Slider,
  ConfigTitle,
  Select,
  BaseButton,
  Radio,
} from "@/components";
import { InputNumber3x, Slider3x, InputWithImg, HotArea } from "@/HOC";

export default function Desc({
  currentPlugin,
  changeCurrentPluginRect,
  updateCurrentPluginProps,
  addSubPlugins,
  deleteInsertPlugin,
  deleteSubPlugins,
}) {
  const { props, rectOptions, extension, subPlugins } = currentPlugin;
  const { subProps } = props;
  const { subRectOptions } = rectOptions;
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="desc-wrap">
      <ConfigTitle
        style={{
          padding: "20px 0 5px 20px",
        }}
        title="上层飞入图"
        detail="图位于轮播上层，切换锚点时飞入"
      />
      <div className="desc-board">
        <Space direction="vertical" size={10}>
          <ConfigTitle
            title="飞入图素材设置"
            detail="对应锚点按组添加，每组上限4张，支持图片链接"
            style={{
              height: 37,
            }}
            titleSize={14}
            detailSize={12}
          />
          <Select
            chooseList={props.flyImageList.map((v, i) => `第${i + 1}组`)}
            onClick={(idx) => {
              setActiveIndex(idx);
            }}
            currentIndex={activeIndex}
            onDrop={(newIndexGrp) => {
              const newList = []
              newIndexGrp.forEach((i) => {
                newList.push(props.flyImageList[i])
              })
              updateCurrentPluginProps({
                flyImageList: newList,
              })
            }}
          />
          <InputWithImg
            labelContent="图片链接1"
            defaultValue={props.flyImageList[activeIndex]?.imageUrl1}
            onChange={(e) => {
              const list = [...props.flyImageList];
              list[activeIndex].imageUrl1 = e;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Input
            labelContent="跳转链接1"
            defaultValue={props.flyImageList[activeIndex]?.clickUrl1}
            onChange={(e) => {
              const list = [...props.flyImageList];
              list[activeIndex].clickUrl1 = e;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <InputWithImg
            labelContent="图片链接2"
            defaultValue={props.flyImageList[activeIndex]?.imageUrl2}
            onChange={(e) => {
              const list = [...props.flyImageList];
              list[activeIndex].imageUrl2 = e;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Input
            labelContent="跳转链接2"
            defaultValue={props.flyImageList[activeIndex]?.clickUrl2}
            onChange={(e) => {
              const list = [...props.flyImageList];
              list[activeIndex].clickUrl2 = e;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <InputWithImg
            labelContent="图片链接3"
            defaultValue={props.flyImageList[activeIndex]?.imageUrl3}
            onChange={(e) => {
              const list = [...props.flyImageList];
              list[activeIndex].imageUrl3 = e;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Input
            labelContent="跳转链接3"
            defaultValue={props.flyImageList[activeIndex]?.clickUrl3}
            onChange={(e) => {
              const list = [...props.flyImageList];
              list[activeIndex].clickUrl3 = e;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <InputWithImg
            labelContent="图片链接4"
            defaultValue={props.flyImageList[activeIndex]?.imageUrl4}
            onChange={(e) => {
              const list = [...props.flyImageList];
              list[activeIndex].imageUrl4 = e;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Input
            labelContent="跳转链接4"
            defaultValue={props.flyImageList[activeIndex]?.clickUrl4}
            onChange={(e) => {
              const list = [...props.flyImageList];
              list[activeIndex].clickUrl4 = e;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Radio
            title="飞入方向1"
            defaultValue={["左", "右", "上", "下"].indexOf(
              props.flyImageList[activeIndex]?.flyDirection1
            )}
            radioList={["左", "右", "上", "下"]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyDirection1 = ["左", "右", "上", "下"][v];
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Slider3x
            title="飞入距离1"
            suffix="px"
            defaultValue={props.flyImageList[activeIndex]?.flyDistance1}
            min={extension.flyDistanceRange[0]}
            max={extension.flyDistanceRange[1]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyDistance1 = v;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Slider
            title="飞入时间1"
            suffix="秒"
            defaultValue={props.flyImageList[activeIndex]?.flyTime1}
            min={extension.flyTimeRange[0]}
            max={extension.flyTimeRange[1]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyTime1 = v;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Space size={10}>
            <InputNumber3x
              labelContent="宽1"
              defaultValue={subRectOptions[0]?.width}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    width: e,
                  },
                  1
                );
              }}
            />
            <InputNumber3x
              labelContent="高1"
              defaultValue={subRectOptions[0]?.height}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    height: e,
                  },
                  1
                );
              }}
            />
          </Space>
          <Space size={10}>
            <InputNumber3x
              labelContent="上边距1"
              defaultValue={subRectOptions[0]?.top}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    top: e,
                  },
                  1
                );
              }}
            />
            <InputNumber3x
              labelContent="左边距1"
              defaultValue={subRectOptions[0]?.left}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    left: e,
                  },
                  1
                );
              }}
            />
          </Space>


          <Radio
            title="飞入方向2"
            defaultValue={["左", "右", "上", "下"].indexOf(
              props.flyImageList[activeIndex]?.flyDirection2
            )}
            radioList={["左", "右", "上", "下"]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyDirection2 = ["左", "右", "上", "下"][v];
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Slider3x
            title="飞入距离2"
            suffix="px"
            defaultValue={props.flyImageList[activeIndex]?.flyDistance2}
            min={extension.flyDistanceRange[0]}
            max={extension.flyDistanceRange[1]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyDistance2 = v;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Slider
            title="飞入时间2"
            suffix="秒"
            defaultValue={props.flyImageList[activeIndex]?.flyTime2}
            min={extension.flyTimeRange[0]}
            max={extension.flyTimeRange[1]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyTime2 = v;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Space size={10}>
            <InputNumber3x
              labelContent="宽2"
              defaultValue={subRectOptions[1]?.width}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    width: e,
                  },
                  2
                );
              }}
            />
            <InputNumber3x
              labelContent="高2"
              defaultValue={subRectOptions[1]?.height}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    height: e,
                  },
                  2
                );
              }}
            />
          </Space>
          <Space size={10}>
            <InputNumber3x
              labelContent="上边距2"
              defaultValue={subRectOptions[1]?.top}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    top: e,
                  },
                  2
                );
              }}
            />
            <InputNumber3x
              labelContent="左边距2"
              defaultValue={subRectOptions[1]?.left}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    left: e,
                  },
                  2
                );
              }}
            />
          </Space>


          <Radio
            title="飞入方向3"
            defaultValue={["左", "右", "上", "下"].indexOf(
              props.flyImageList[activeIndex]?.flyDirection3
            )}
            radioList={["左", "右", "上", "下"]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyDirection3 = ["左", "右", "上", "下"][v];
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Slider3x
            title="飞入距离3"
            suffix="px"
            defaultValue={props.flyImageList[activeIndex]?.flyDistance3}
            min={extension.flyDistanceRange[0]}
            max={extension.flyDistanceRange[1]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyDistance3 = v;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Slider
            title="飞入时间3"
            suffix="秒"
            defaultValue={props.flyImageList[activeIndex]?.flyTime3}
            min={extension.flyTimeRange[0]}
            max={extension.flyTimeRange[1]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyTime3 = v;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Space size={10}>
            <InputNumber3x
              labelContent="宽3"
              defaultValue={subRectOptions[2]?.width}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    width: e,
                  },
                  3
                );
              }}
            />
            <InputNumber3x
              labelContent="高3"
              defaultValue={subRectOptions[2]?.height}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    height: e,
                  },
                  3
                );
              }}
            />
          </Space>
          <Space size={10}>
            <InputNumber3x
              labelContent="上边距3"
              defaultValue={subRectOptions[2]?.top}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    top: e,
                  },
                  3
                );
              }}
            />
            <InputNumber3x
              labelContent="左边距3"
              defaultValue={subRectOptions[2]?.left}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    left: e,
                  },
                  3
                );
              }}
            />
          </Space>

          <Radio
            title="飞入方向4"
            defaultValue={["左", "右", "上", "下"].indexOf(
              props.flyImageList[activeIndex]?.flyDirection4
            )}
            radioList={["左", "右", "上", "下"]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyDirection4 = ["左", "右", "上", "下"][v];
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />



          <Slider3x
            title="飞入距离4"
            suffix="px"
            defaultValue={props.flyImageList[activeIndex]?.flyDistance4}
            min={extension.flyDistanceRange[0]}
            max={extension.flyDistanceRange[1]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyDistance4 = v;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />








          <Slider
            title="飞入时间4"
            suffix="秒"
            defaultValue={props.flyImageList[activeIndex]?.flyTime4}
            min={extension.flyTimeRange[0]}
            max={extension.flyTimeRange[1]}
            onChange={(v) => {
              const list = [...props.flyImageList];
              list[activeIndex].flyTime4 = v;
              updateCurrentPluginProps({
                flyImageList: list,
              });
            }}
          />
          <Space>
            <BaseButton
              style={{
                width: 155,
              }}
              type="normal"
              icon={<DeleteFilled />}
              content="删除本组"
              onClick={() => {
                const list = [...props.flyImageList];
                list.splice(activeIndex, 1);
                if (list.length === 0) {
                  deleteInsertPlugin(currentPlugin.insertId);
                } else {
                  setActiveIndex(0);
                  updateCurrentPluginProps({
                    flyImageList: list,
                  });
                }
              }}
            />
            <BaseButton
              style={{
                width: 155,
              }}
              type="normal"
              icon={<PlusOutlined />}
              content="添加帧数"
              onClick={() => {
                const list = [...props.flyImageList];
                list.push(Object.assign({}, extension.struct));
                updateCurrentPluginProps({
                  flyImageList: list,
                });
              }}
            />
          </Space>





          <Space size={10}>
            <InputNumber3x
              labelContent="宽4"
              defaultValue={subRectOptions[3]?.width}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    width: e,
                  },
                  4
                );
              }}
            />
            <InputNumber3x
              labelContent="高4"
              defaultValue={subRectOptions[3]?.height}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    height: e,
                  },
                  4
                );
              }}
            />
          </Space>
          <Space size={10}>
            <InputNumber3x
              labelContent="上边距4"
              defaultValue={subRectOptions[3]?.top}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    top: e,
                  },
                  4
                );
              }}
            />
            <InputNumber3x
              labelContent="左边距4"
              defaultValue={subRectOptions[3]?.left}
              onChange={(e) => {
                changeCurrentPluginRect(
                  {
                    left: e,
                  },
                  4
                );
              }}
            />
          </Space>
        </Space>
      </div>
    </div>
  );
}
