import React, { useRef, useCallback, useEffect, useState } from 'react'


export default function Preview({
    rectOptions,
    props,
    currentIndex = 0,
    wrapIndex = 1,
    zIndex = 0,
}) {

    const { width, height, left, top } = rectOptions
    const {imageUrl } = props
    return (
        <img
            style={{
                width,
                height,
                position: 'absolute',
                left,
                top,
                zIndex,
                //这里props没有动画的参数，现在做不了动画
                // transform: `rotate(${rotate}deg)`,
                // transition: `transform ${animateTime}s linear`
            }}
            // onTransitionEnd={() => {
            //     setRotate(-rotate)
            // }}
            onClick={() => {
                window.location.href = props?.jumpurl
            }}
            src={imageUrl}
            alt=""
        />

    )
}