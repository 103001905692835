import React, { useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/swiper-element.min.css'
import 'swiper/swiper-element-bundle.min.css'
import 'swiper/swiper-bundle.min.css'

export default function Preview({
    rectOptions,                    //画布组件的尺寸、位置等数据
    props,                          //组件的配置项数据
    onChange = () => { },           //有手势交互时需更新的素材index（由模块功能决定，例轮播等需要手势切换）
    currentIndex = 0,               //当前显示的素材index（由模块功能决定，例轮播等多组数据切换）
    zIndex = 0                      //组件在模块中的显示层级
}) {
    const { width, height, left, top } = rectOptions        //组件及子组件的宽、高、上边距、左边距
    const { banners, duration, direction } = props          //组件及子组件的配置项
    const swiperRef = useRef(null)
    const curIndexRef = useRef(currentIndex)
    useEffect(() => {
        if (currentIndex !== curIndexRef.current) {
            swiperRef.current.swiper.slideToLoop(currentIndex)
        }
    }, [currentIndex])
    return (                                                //组件的完整功能效果
        <div
            style={{
                width,
                height,
                left,
                top,
                zIndex,
                position: 'absolute',
            }}
        >
            <Swiper
                style={{
                    width: '100%',
                    height: '100%',
                }}
                modules={[Autoplay]}
                loop
                direction={direction === 0 ? 'horizontal' : 'vertical'}
                autoplay={{
                    delay: duration * 1000,
                    disableOnInteraction: false
                }}
                onSlideChangeTransitionEnd={(e) => {
                }}
                onSlideChange={(e) => {
                    curIndexRef.current = e.realIndex
                    onChange(e.realIndex)
                }}
                effect="slide"
                ref={swiperRef}
            >
                {
                    banners.map((banner, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div
                                    style={{
                                        width,
                                        height,
                                        position: 'relative'
                                    }}

                                >
                                    <img
                                        src={banner.imageUrl}
                                        alt=""
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain'
                                        }}
                                    />
                                    {banner.hotArea.map((hot, hotIndex) => {
                                        return (
                                            <div
                                                key={hotIndex + 'hotIndex'}
                                                style={{
                                                    width: hot.width,
                                                    height: hot.height,
                                                    position: 'absolute',
                                                    left: hot.left,
                                                    top: hot.top,
                                                }}
                                                onClick={() => {
                                                    window.open(hot.jumpLink)
                                                }}
                                            >
                                                {
                                                    hot.showPrice && (
                                                        <div
                                                            style={{
                                                                color: hot.priceConfig.color,
                                                                fontSize: hot.priceConfig.fontSize,
                                                                fontWeight: hot.priceConfig.fontWeight,
                                                                position: 'absolute',
                                                                left: hot.priceConfig.left,
                                                                top: hot.priceConfig.top,
                                                            }}
                                                        >{hot.priceConfig.showPrefiex ? '￥' : ''}{hot.priceConfig.useInputPrice ? hot.priceConfig.inputPrice : '100'}</div>
                                                    )
                                                }

                                            </div>
                                        )
                                    })}
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>

    )
}