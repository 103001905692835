import React, { useState } from 'react'
import { Space, Checkbox } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import Comp from './comp'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    ConfigTitle,
    Select,
    BaseButton,
    Radio,
} from '@/components'
import { InputNumber3x, Slider3x, InputWithImg, InputNumWithTitle, HotArea } from '@/HOC'
import _ from 'lodash'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    addSubPlugins,
    deleteInsertPlugin,
    deleteSubPlugins,
}) {
    const { props, rectOptions, extension, subPlugins } = currentPlugin
    const { subProps } = props
    const { subRectOptions } = rectOptions
    const [activeIndex, setActiveIndex] = useState(0)
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="飞入图带热区"
                detail="图片可向左或向右滑动切换，支持自动播放"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                    align="start"
                >
                    <ConfigTitle
                        title="轮播素材设置"
                        detail="设置轮播图片及跳转链接"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Select
                        chooseList={subProps.map((v, i) => `第${i + 1}张`)}
                        onClick={(idx) => {
                            setActiveIndex(idx)
                        }}
                        currentIndex={activeIndex}
                        useDrag={false}
                    />
                    <Checkbox
                        checked={subProps[activeIndex].show}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                show: e.target.checked,
                            }, activeIndex + 1)
                        }}
                    >是否显示</Checkbox>
                    <InputWithImg
                        labelContent="图片链接"
                        defaultValue={subProps[activeIndex].imageUrl}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                imageUrl: e,
                            }, activeIndex + 1)
                        }}
                    />
                    <HotArea
                        editContent={subProps[activeIndex].imageUrl}
                        defaultValue={subProps[activeIndex].hotArea}
                        editContentSize={{
                            width: subRectOptions[activeIndex].width,
                            height: subRectOptions[activeIndex].height,
                        }}
                        contentFit="fill"
                        onSave={(data) => {
                            updateCurrentPluginProps({
                                hotArea: data,
                            }, activeIndex + 1)
                        }}
                        showCopyBtn={activeIndex !== 0}
                        onCopy={() => {
                            const newHotArea = _.cloneDeep(subProps[0].hotArea, {})
                            updateCurrentPluginProps({
                                hotArea: newHotArea,
                            }, activeIndex + 1)
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            defaultValue={subRectOptions[activeIndex].width}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    width: e,
                                }, activeIndex + 1)
                            }}
                        />
                        <InputNumber3x
                            labelContent="高"
                            defaultValue={subRectOptions[activeIndex].height}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    height: e,
                                }, activeIndex + 1)
                            }}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            defaultValue={subRectOptions[activeIndex].top}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    top: e,
                                }, activeIndex + 1)
                            }}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            defaultValue={subRectOptions[activeIndex].left}
                            onChange={(e) => {
                                changeCurrentPluginRect({
                                    left: e,
                                }, activeIndex + 1)
                            }}
                        />
                    </Space>
                    <Radio
                        title="飞入方向"
                        defaultValue={extension.flyDirectionRadioList.indexOf(subProps[activeIndex].flyDirection)}
                        radioList={extension.flyDirectionRadioList}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                flyDirection: extension.flyDirectionRadioList[v]
                            }, activeIndex + 1)
                        }}
                    />
                    <Slider3x
                        title="飞入距离"
                        suffix="px"
                        defaultValue={subProps[activeIndex].flyDistance}
                        min={extension.flyDistanceRange[0]}
                        max={extension.flyDistanceRange[1]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                flyDistance: v
                            }, activeIndex + 1)
                        }}
                    />
                    <Radio
                        title="动画效果"
                        defaultValue={extension.animateTypeRadioList.indexOf(subProps[activeIndex].animateType)}
                        radioList={extension.animateTypeRadioList}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                animateType: extension.animateTypeRadioList[v]
                            }, activeIndex + 1)
                        }}
                    />
                    <InputNumWithTitle
                        labelContent="缩放倍数"
                        defaultValue={subProps[activeIndex].scale}
                        step={0.1}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                scale: v
                            }, activeIndex + 1)
                        }}
                    />
                    <Slider3x
                        title="滑动距离"
                        suffix="px"
                        defaultValue={subProps[activeIndex].scrollDistance}
                        min={extension.scrollDistanceRange[0]}
                        max={extension.scrollDistanceRange[1]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                scrollDistance: v
                            }, activeIndex + 1)
                        }}
                    />
                    <Slider
                        title="动画频率"
                        suffix="秒"
                        defaultValue={subProps[activeIndex].animateRate}
                        min={extension.animateRateRange[0]}
                        max={extension.animateRateRange[1]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                animateRate: v
                            }, activeIndex + 1)
                        }}
                    />
                </Space>
            </div>
        </div>
    )
}