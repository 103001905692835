import React, { useState } from 'react'
import { Space, DatePicker, Checkbox } from 'antd'
import dayjs from 'dayjs'
import '@/plugins/desc.less'
import {
    Slider,
    Select,
    ColorPicker,
    ConfigTitle,
} from '@/components'
import { InputNumber3x, InputWithImg } from '@/HOC'

const { RangePicker } = DatePicker

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const { fontSizeDuration, fontWeightDuration } = extension
    const [currentIndex, setCurrentIndex] = useState(0)
    const curSubProps = props.subProps[currentIndex]
    const curSubRect = rectOptions.subRectOptions[currentIndex]
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 10px'
                }}
                title="倒计时"
                detail="设置活动时间，精准走秒"
            />
            <div className="desc-board">
                <Space
                    style={{
                        padding: '0px 10px',
                    }}
                    align="start"
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="活动时间"
                        detail="设置活动的起止时间，支持天、时、分、秒的单独调整"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <RangePicker
                        defaultValue={[dayjs(props.startTime), dayjs(props.endTime)]}
                        style={{ width: 340 }}
                        showTime
                        onChange={(e) => {
                            const startDate = new Date(e[0].$d)
                            const endDate = new Date(e[1].$d)
                            updateCurrentPluginProps({
                                startTime: startDate.getTime(),
                                endTime: endDate.getTime(),
                            })
                        }}
                    />
                    <Select
                        style={{
                            width: 340,
                        }}
                        chooseList={['天', '时', '分', '秒']}
                        onClick={(idx) => {
                            setCurrentIndex(idx)
                        }}
                        currentIndex={currentIndex}
                        useDrag={false}
                    />
                    <Checkbox
                        checked={curSubProps.show}
                        onChange={(e) => {
                            updateCurrentPluginProps({
                                show: e.target.checked,
                            }, currentIndex + 1)
                        }}
                    >是否显示</Checkbox>
                    <Space size={30}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                curSubRect.top = val
                                changeCurrentPluginRect(curSubRect, currentIndex + 1)
                            }}
                            defaultValue={curSubRect.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                curSubRect.left = val
                                changeCurrentPluginRect(curSubRect, currentIndex + 1)
                            }}
                            defaultValue={curSubRect.left}
                        />
                    </Space>
                    <Slider
                        title="文字大小"
                        min={fontSizeDuration[0]}
                        max={fontSizeDuration[1]}
                        defaultValue={curSubProps.fontSize}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                fontSize: v,
                            }, currentIndex + 1)
                        }}
                        suffix="px"
                    />

                    <Slider
                        title="文字粗细"
                        min={fontWeightDuration[0]}
                        max={fontWeightDuration[1]}
                        step={100}
                        defaultValue={curSubProps.fontWeight}
                        suffix=''
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                fontWeight: v
                            }, currentIndex + 1)
                        }}
                    />
                    <ColorPicker
                        title="文字颜色"
                        defaultValue={curSubProps.color}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                color: v
                            }, currentIndex + 1)
                        }}
                    />
                </Space>
            </div>
        </div>
    )
}