import React, { useRef, useEffect } from 'react'
import './index.less'

export default function LoopScrollPreview({
    props,
    rectOptions,
    zIndex = 0,
}) {
    const { scrollList, between, scrollItemWidth, scrollItemHeight, subProps } = props
    const { left, top, subRectOptions, width } = rectOptions
    const { radius, defaultColor, activeColor } = subProps[0]
    const subLeft = subRectOptions[0].left
    const subTop = subRectOptions[0].top
    const subWidth = subRectOptions[0].width
    const subHeight = subRectOptions[0].height

    const scrollRef = useRef(null)
    const dotRef = useRef(null)

    useEffect(() => {
        const contentWidth = scrollList.length * (scrollItemWidth + between) - width
        const radio = contentWidth / subWidth
        const handleScroll = (e) => {
            dotRef.current.style.transform = `translateX(${-subWidth + (e.target.scrollLeft / radio)}px)`
        }
        scrollRef.current.addEventListener('scroll', handleScroll, false)
        return () => {
            scrollRef.current.removeEventListener('scroll', handleScroll, false)
        }
    }, [])

    return (
        <>
            <div
                style={{
                    width,
                    height: scrollItemHeight,
                    overflow: 'scroll',
                    position: 'absolute',
                    left,
                    top,
                    zIndex,
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}
                ref={scrollRef}
            >
                {
                    scrollList.map((item, index) => {
                        return (
                            <div
                                key={index + 'loop-scroll'}
                                style={{
                                    position: 'relative',
                                    width: scrollItemWidth,
                                    height: scrollItemHeight,
                                    marginLeft: between,
                                }}
                            >

                                <img
                                    style={{
                                        width: scrollItemWidth,
                                        height: scrollItemHeight,
                                    }}
                                    draggable={false}
                                    src={item.imageUrl}
                                    alt=""
                                />
                                {
                                    item.hotArea.map((hot, hotIndex) => {
                                        return (
                                            <div
                                                key={hotIndex + 'hotIndex'}
                                                style={{
                                                    width: hot.width,
                                                    height: hot.height,
                                                    position: 'absolute',
                                                    left: hot.left,
                                                    top: hot.top,
                                                }}
                                                onClick={() => {
                                                    window.open(hot.jumpLink)
                                                }}
                                            >
                                                {
                                                    hot.showPrice && (
                                                        <div
                                                            style={{
                                                                color: hot.priceConfig.color,
                                                                fontSize: hot.priceConfig.fontSize,
                                                                fontWeight: hot.priceConfig.fontWeight,
                                                                position: 'absolute',
                                                                left: hot.priceConfig.left,
                                                                top: hot.priceConfig.top,
                                                                userSelect: 'none',
                                                            }}
                                                        >{hot.priceConfig.showPrefiex ? '￥' : ''}{hot.priceConfig.useInputPrice ? hot.priceConfig.inputPrice : '100'}</div>
                                                    )
                                                }

                                            </div>
                                        )
                                    })
                                }
                            </div>

                        )
                    })
                }
            </div>
            <div
                style={{
                    position: 'absolute',
                    width: subWidth,
                    height: subHeight,
                    overflow: 'hidden',
                    borderRadius: radius,
                    backgroundColor: defaultColor,
                    left: subLeft,
                    zIndex,
                    top: subTop
                    // transform: `translate(${left}px, ${top}px)`,
                }}
            >
                <div
                    ref={dotRef}
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: activeColor,
                        borderRadius: radius,
                        transform: `translateX(-${subWidth}px)`
                    }}
                ></div>
            </div>
        </>

    )
}