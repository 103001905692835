import React from 'react'

export default function HotArea({ hot, style = {}, catchPrice = 45}) {
    return (
        <div
            style={Object.assign({
                width: hot.width,
                height: hot.height,
                position: 'absolute',
                left: hot.left,
                top: hot.top,
            }, style)}
            onClick={() => {
                window.open(hot.jumpLink)
            }}
        >
            {
                hot.showPrice && (
                    <div
                        style={{
                            color: hot.priceConfig.color,
                            fontSize: hot.priceConfig.fontSize,
                            fontWeight: hot.priceConfig.fontWeight,
                            position: 'absolute',
                            left: hot.priceConfig.left,
                            top: hot.priceConfig.top,
                        }}
                    >{hot.priceConfig.showPrefix ? '￥' : ''}{hot.priceConfig.useInputPrice ? hot.priceConfig.inputPrice : catchPrice}</div>
                )
            }

        </div>
    )
}