import React from 'react'
import schema from './schema.json'
import Comp from './comp'
import Desc from './desc'
import Preview from './preview'
const defaultRectOptions = {
    width: 375,
    height: 667,
    left: 0,
    top: 0,
    rotate: 0,
    subRectOptions: []
}
const defaultDrawOptions = {
    resize: true,
    drag: true,
    rotate: false,
    keepRatio: true,
    renderDirections: ["nw","n","ne","w","e","sw","s","se"],
    subOptions: [],
}
const { data, draw_ui } = schema

let props = {
    subProps: [],
}
let rectOptions = {
    subRectOptions: []
}
let extension = {}
let drawOptions = {
    subOptions: [],
}

const { main_data } = data

Object.keys(data).forEach((d, i) => {
    Object.keys(data[d].properties).forEach((k, n) => {
        const item = data[d].properties[k]
        if (d === 'main_data') {
            props[k] = item.value
        } else {
            const { subProps } = props
            if (subProps[i]) {
                subProps[i][k] = item.value
            } else {
                subProps[i] = {}
            }
        }
        if (item.range) {
            extension[k + 'Range'] = item.range.split('-').map(n => isNaN(Number(n)) ? n : Number(n))
        }
    })
    Object.keys(data[d].rect).forEach((k, n) => {
        const item = data[d].rect[k]
        if (d === 'main_data') {
            rectOptions[k] = item.value
        } else {
            const { subRectOptions } = rectOptions
            if (subRectOptions[i]) {
                subRectOptions[i][k] = item.value
            } else {
                subRectOptions[i] = {}
            }
        }
        
    })
})
rectOptions = Object.assign(defaultRectOptions, rectOptions)
drawOptions = Object.assign(defaultDrawOptions, draw_ui[0].draw_options)
const Plugin = {
    name: schema.title,
    type: schema.type,
    render: Comp,
    board: Desc,
    subPlugins: [],
    preview: Preview,
    props,
    extension,
    rectOptions,
    drawOptions,
}

export default Plugin