import React, { Fragment, useState } from 'react'
import { ORIGIN_DATA_TYPE } from '@/consts/plugin'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import SpaceDesc from '../SpaceDesc'
import _ from 'lodash'

import {
    Input, // 文本输入框 ---- 文本填写
    ConfigTitle, // 标题，可用作大标题和小标题
    InputNumber, // 数字输入框 ---- 数字填写（涉及尺寸的时候）
    Slider, // 滑动条 ---- 滑动游尺
    Radio, // 单选选择器 ---- 单选框
    Select, // select Tab 选择器 ---- 涉及到轮播等添加帧数的组件时候用到（具体使用参考HotBanner组件)
    BaseButton, // 按钮 ---- 按钮 具体用法参考AccordionBanner 第79-98
    ColorPicker, // 颜色选择器 ---- 颜色选择
    TextArea, // 多行文本输入框
} from '@/components'
import {
    HotArea, // 热区 ---- 热区（具体使用参考HotBanner组件)
    InputNumber3x, // 3倍数字输入框 ---- 数字填写（涉及尺寸的时候）
    Slider3x, // 3倍数字滑动条 ---- 滑动游尺（涉及尺寸的时候）
    InputWithImg, // 输入框带图片或视频 ---- 媒体资源填写
} from '@/HOC'
import { parseDrawUIProps } from '../../../schemaUtils'

const GroupDesc = ({
    props,
    rectOptions,
    config,
    updateCurrentPluginProps,
    changeCurrentPluginRect,
    deleteInsertPlugin,
    dataType = ORIGIN_DATA_TYPE.PROPERTIES,
    currentPlugin,
    schema
}) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    if (!props[config.origin_data.key] || !config.children) return null
    const groupData = dataType === ORIGIN_DATA_TYPE.PROPERTIES ? props[config.origin_data.key] : rectOptions[config.origin_data.key]
    const renderMethod = dataType === ORIGIN_DATA_TYPE.PROPERTIES ? updateCurrentPluginProps : changeCurrentPluginRect

    return (
        <>
            <Select
                chooseList={props[config.origin_data.key].map((v, i) => `第${i + 1}组`)}
                onClick={(idx) => {
                    setCurrentIndex(idx)
                }}
                currentIndex={currentIndex}
                onDrop={(newIndexGrp) => {
                    const newList = []
                    newIndexGrp.forEach((i) => {
                        newList.push(props[config.origin_data.key][i])
                    })
                    renderMethod({
                        [`${config.origin_data.key}`]: newList
                    })
                }}
            />
            {
                config.children.map((child, index) => {
                    return (
                        <Fragment key={index}>
                            {
                                child.type === 'InputWithImg' && <InputWithImg
                                    labelContent={
                                        child.props.labelContent
                                    }
                                    defaultValue={groupData[currentIndex][child.origin_data.key]}
                                    onChange={(val) => {
                                        const newList = [...groupData]
                                        newList[currentIndex][child.origin_data.key] = val
                                        renderMethod({
                                            [`${config.origin_data.key}`]: newList
                                        })
                                    }}
                                />
                            }
                            {
                                child.type === 'Input' && <Input
                                    labelContent={
                                        child.props.labelContent
                                    }
                                    defaultValue={groupData[currentIndex][child.origin_data.key]}
                                    onChange={(val) => {
                                        const newList = [...groupData]
                                        newList[currentIndex][child.origin_data.key] = val
                                        renderMethod({
                                            [`${config.origin_data.key}`]: newList
                                        })
                                    }}
                                />
                            }
                            {
                                child.type === 'InputNumber3x' && <InputNumber3x
                                    labelContent={
                                        child.props.labelContent
                                    }
                                    style={{
                                        color: '#696969',
                                    }}
                                    defaultValue={groupData[currentIndex][child.origin_data.key]}
                                    onChange={(val) => {
                                        const newList = [...groupData]
                                        newList[currentIndex][child.origin_data.key] = val
                                        renderMethod({
                                            [`${config.origin_data.key}`]: newList
                                        })
                                    }}
                                />
                            }
                            {
                                child.type === 'Space' && <SpaceDesc
                                    props={groupData[currentIndex]}
                                    config={child}
                                    updateCurrentPluginProps={(newData) => {
                                        const newList = [...groupData]
                                        const key = Object.keys(newData)[0]
                                        newList[currentIndex][key] = newData[key]
                                        renderMethod({
                                            [`${config.origin_data.key}`]: newList
                                        })
                                    }}
                                    dataType={dataType}
                                    schema={schema}
                                />
                            }
                            {
                                child.type === 'HotArea' && <HotArea
                                    editContent={
                                        child.props.editContent.target.indexOf('__array__') > -1
                                            ? parseDrawUIProps(groupData[currentIndex], child.props.editContent.target.split('.').slice(1, Infinity).join('.'))
                                            : parseDrawUIProps(Object.assign(props, rectOptions), child.props.editContent.target)
                                        }
                                    editContentSize={{
                                        width: child.props.editContentSize.width.target.indexOf('__array__') > -1
                                            ? parseDrawUIProps(groupData[currentIndex], child.props.editContentSize.width.target.split('.').slice(1, Infinity).join('.'))
                                            : parseDrawUIProps(Object.assign(props, rectOptions), child.props.editContentSize.width.target),
                                        height: child.props.editContentSize.height.target.indexOf('__array__') > -1
                                        ? parseDrawUIProps(groupData[currentIndex], child.props.editContentSize.height.target.split('.').slice(1, Infinity).join('.'))
                                        : parseDrawUIProps(Object.assign(props, rectOptions), child.props.editContentSize.height.target)
                                    }}
                                    defaultValue={groupData[currentIndex][child.origin_data.key]}
                                    contentFit={child.props.contentFit}
                                    onSave={(data) => {
                                        const newList = [...groupData]
                                        newList[currentIndex][child.origin_data.key] = data
                                        renderMethod({
                                            [`${config.origin_data.key}`]: newList
                                        })
                                    }}
                                    showCopyBtn={currentIndex !== 0}
                                    onCopy={() => {
                                        const newList = [...groupData]
                                        newList[currentIndex].hotArea = _.cloneDeep(newList[0].hotArea, {})
                                        updateCurrentPluginProps({
                                            [`${config.origin_data.key}`]: newList
                                        })
                                    }}
                                />
                            }
                        </Fragment>
                    )
                })
            }
            <Space>
                <BaseButton
                    style={{
                        width: 155
                    }}
                    type="normal"
                    icon={<DeleteFilled />}
                    content="删除本组"
                    onClick={() => {
                        const list = [...groupData]
                        list.splice(currentIndex, 1)
                        if (list.length === 0) {
                            deleteInsertPlugin(currentPlugin.insertId)
                        } else {
                            setCurrentIndex(0)
                            updateCurrentPluginProps({
                                [`${config.origin_data.key}`]: list,
                            })
                        }
                    }}
                />
                <BaseButton
                    style={{
                        width: 155
                    }}
                    type="normal"
                    icon={<PlusOutlined />}
                    content="添加帧数"
                    onClick={() => {
                        const list = [...groupData]
                        const addDataOrigin = schema.data[config.origin_data.name]
                        const subAddDataOrigin = dataType === ORIGIN_DATA_TYPE.PROPERTIES ? addDataOrigin['properties'] : addDataOrigin['rect']
                        const template = subAddDataOrigin[config.origin_data.key].value ? subAddDataOrigin[config.origin_data.key].value[0] : subAddDataOrigin[config.origin_data.key][0]
                        list.push(Object.assign({}, template))
                        updateCurrentPluginProps({
                            [`${config.origin_data.key}`]: list
                        })
                    }}
                />
            </Space>
        </>
    )
}

export default GroupDesc