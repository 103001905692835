import { useEffect, useRef, useContext } from 'react'
import { InsertContext } from '@/context/InsertProvider'
import { computeMediaUrlRect } from '.'

export const useUpdateEffect = (fn, inputs) => {
    const flag = useRef(false)
    useEffect(() => {
        if (flag.current) {
            fn()
        } else {
            flag.current = true
        }
    }, inputs)
}

export const useRefreshMediaRect = (url, rect, index = 0) => {
    const { changeCurrentPluginRect } = useContext(InsertContext)
    useUpdateEffect(() => {
        computeMediaUrlRect(url).then(({ ratio }) => {
            rect.height = rect.width / ratio
            changeCurrentPluginRect(rect, index)
        }, (err) => {
            console.log(err, 'error message')
        })
    }, [url])
}