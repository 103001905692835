import React, { useRef, useCallback, useEffect } from 'react'
import { CardSlider } from '@/components'
export default function Preview({
    baseConfig,
    rectOptions,
    props,
    zIndex = 0,
}) {
    const maxWidth = baseConfig.width
    const maxHeight = baseConfig.height
    const { width, height, left, top } = rectOptions
    const { bannerWidth, between, interval, banners } = props
    const timer = useRef(null)
    const stopAutoScroll = useCallback(() => {
        clearInterval(timer.current)
    }, [timer])
    useEffect(() => {
        return stopAutoScroll
    }, [])

    return (
        <div
            style={{
                position: 'absolute',
                width,
                height,
                top: 0,
                left: 0,
                zIndex,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    width,
                    height,
                    left,
                    top,
                    overflow: 'hidden',
                }}
            >
                <CardSlider
                    banners={banners}
                    wrapWidth={width}
                    between={between}
                    interval={interval}
                    bannerRect={{
                        width: bannerWidth,
                        height: height
                    }}
                    onClickItem={(idx) => {
                        window.open(banners[idx].jumpUrl)
                    }}
                />

            </div>
        </div>
    )
}