import React, { useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/swiper-element.min.css'
import 'swiper/swiper-element-bundle.min.css'
import 'swiper/swiper-bundle.min.css'

export default function Preview({
    rectOptions,
    props,
    onChange = () => { },
    currentIndex = 0,
    zIndex = 0
}) {
    const { width, height, left, top } = rectOptions
    const { banners } = props
    return (
        <div
            style={{
                width,
                height,
                left,
                top,
                zIndex,
                position: 'absolute',
            }}
        >
            <div
                style={{
                    width,
                    height,
                    position: 'relative'
                }}

            >
                <img
                    src={banners[currentIndex].imageUrl}
                    alt=""
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                    }}
                />
            </div>
        </div>

    )
}