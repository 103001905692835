import React, { useState } from 'react'
import './index.less'

export default function Comp ({ rectOptions, props }) {
    const { left, top,width,height } = rectOptions
    const { navList, between, direction, navWidth, navHeight } = props
    const [curIndex, setCurIndex] = useState(0)
    return (
        <div className="nav-comp-wrap"
        style={{
            transform: `translate(${left}px, ${top}px)`,
            width
        }}
    >
        <div style={{
            display:"flex",
            flexDirection:"row",
            height:navHeight,
            flexWrap: 'wrap'
        }}>
             {
            navList.map((nav, index) => {
                const { activeUrl, defaultUrl } = nav
                return (
                    <img
                        key={index}
                        style={{
                            width: navWidth,
                            height: "100%",
                            marginLeft: direction === 0 || index === 0 ? 0 : between,
                            marginTop: direction === 1 || index === 0 ? 0 : between,
                        }}
                        onClick={(e) => {
                            setCurIndex(index)
                        }}
                        src={index === curIndex ? activeUrl : defaultUrl} alt=""
                    />
                )
            })
        }
        </div>
        </div>
    )
}