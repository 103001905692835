import React, { useRef, useCallback, useEffect, useState } from 'react'
// import { Image, View } from '@tarojs/components'
// import { handlePress, rpx1x } from '../../utils'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper'
// import { CustomScrollView } from '@conecli/cone-render/components'

import 'swiper/swiper.min.css'
import 'swiper/swiper-element.min.css'
import 'swiper/swiper-element-bundle.min.css'
import 'swiper/swiper-bundle.min.css'

export default function Preview({
    rectOptions,
    props,
    currentIndex = 0,
    wrapIndex = 1,
    zIndex = 0,
}) {
    const videoRef = useRef(null)
    const { width, height, left, top } = rectOptions
    const { KVFrame, NestBannerImageList, interval } = props
    const [activeIndex, setActiveIndex] = useState(currentIndex)
    useEffect(() => {
        setActiveIndex(currentIndex)
    }, [currentIndex])
    return (
        <>
            {
                currentIndex === Math.floor(KVFrame) - 1 && <>
                    <div
                        style={{
                            width: width,
                            height: height,
                            left: left,
                            top: top,
                            zIndex,
                            position: 'absolute',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            scrollX
                        >
                            <Swiper
                                modules={[Autoplay]}
                                loop
                                autoplay={{
                                    delay: interval * 1000,
                                    disableOnInteraction: false
                                }}
                                onSlideChangeTransitionEnd={(e) => {
                                }}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                onSlideChange={(e) => {
                                    setActiveIndex(e.realIndex)
                                }}
                            >
                                {
                                    NestBannerImageList.map((banner, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <img
                                                        src={banner.imageUrl}
                                                        alt=""
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>

                    </div>
                </>
            }


        </>

    )
}