import React from "react";
// import { rpx1x } from "../../utils";

export default function Preview({ rectOptions, props, zIndex = 0, onClick }) {
    const { width, height, left, top } = rectOptions
    const { imageUrl } = props
    return (
        <div
            style={{
                width: width,
                height: height,
                position: 'absolute',
                zIndex,
                left: left,
                top: top
            }}
            onClick={() => {
                onClick()
            }}
        >
           <img src={imageUrl} alt="" style={{ width: '100%', height: '100%' }} />
        </div>
    )
}