import Comp from "./comp";
import Desc from "./desc";
import Preview from "./preview";

const defaultRectOptions = {
  width: 375,
  height: 667,
  left: 0,
  top: 0,
  rotate: 0,
  subRectOptions: [],
};

const defaultDrawOptions = {
  resize: true,
  drag: true,
  rotate: false,
  keepRatio: true,
  renderDirections: ["nw", "n", "ne", "w", "e", "sw", "s", "se"],
  subOptions: [],
};

const plugin = {
  name: "下层飞入图",
  type: "BottomBannerFly-image",
  render: () => <></>,
  board: Desc,
  preview: Preview,
  subPlugins: [Comp],
  props: {
    flyImageList: [
      {
        imageUrl1:
          "//img30.360buyimg.com/zx/jfs/t1/121697/12/17116/402477/5fa13848Eebff5399/78274fd96a731399.jpg",
        clickUrl1: "",
        flyDirection1: "左",
        flyDistance1: 300,
        flyTime1: 2,
        hotArea1: [],
      },
    ],
    subProps: [],
  },
  extension: {
    struct: {
      imageUrl1:
        "//img12.360buyimg.com/zx/jfs/t1/154453/8/30647/50874/6695d93cF0ff84853/310e12dfed33faad.jpg",
      clickUrl1: "",
      flyDirection1: "左",
      flyDistance1: 300,
      flyTime1: 2,
      hotArea1: [],
    },
    flyDistanceRange: [0, 500],
    flyTimeRange: [0, 10],
  },
  rectOptions: Object.assign(defaultRectOptions, {
    subRectOptions: [
      {
        width: 375,
        height: 400,
        top: 16,
        left: 8,
      },
      {
        width: 200,
        height: 100,
        top: 160,
        left: 100,
      },
    ],
  }),
  drawOptions: Object.assign(defaultDrawOptions, {
    subOptions: [
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
      {
        resize: true,
        drag: true,
        rotate: false,
        keepRatio: true,
      },
    ],
  }),
};

export default plugin;
