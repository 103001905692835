import React, { useRef, useEffect } from 'react'

export default function Preview({
    rectOptions,
    props,
    zIndex = 0,
}) {
    const { subRectOptions } = rectOptions
    const { subProps } = props
    const allFlyWrapList = useRef([])
    const allFlyImageList = useRef([])
    const timerGrp = useRef([])
    const wrapTimerGrp = useRef([])
    const animateStartIndex = useRef([])
    const isEnterAnimate = useRef(false)
    const hybridList = subRectOptions.map((item, index) => {
        return Object.assign(item, subProps[index])
    }).filter(n => n.show)

    const flyImageId = 'tgb_fly-image-normal_' + Date.now()

    useEffect(() => {
        hybridList.forEach((item, index) => {
            allFlyWrapList.current.push(document.getElementById(`${flyImageId + '-' + index}`))
            allFlyImageList.current.push(document.querySelector(`#${flyImageId + '-' + index} > div`))
            timerGrp.current.push(null)
            wrapTimerGrp.current.push(null)
            animateStartIndex.current.push(false)
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            allFlyWrapList.current.forEach((elem, index) => {
                const item = hybridList[index]
                elem.style.transition = `${item.flyTime}s`
                elem.style.transform = 'translate(0, 0)'
                wrapTimerGrp.current[index] = setTimeout(() => {
                    flyIntervalAnimate(allFlyImageList.current[index], item, index)
                }, item.flyTime * 1000)
            })
        }, 60)
    }, [])

    const initAnimateStyle = (hybridItem) => {
        let style = {}
        switch (hybridItem.flyDirection) {
            case '左':
                style = {
                    transform: `translateX(-${hybridItem.flyDistance}px)`,
                }
                break
            case '右':
                style = {
                    transform: `translateX(${hybridItem.flyDistance}px)`
                }
                break
            case '上':
                style = {
                    transform: `translateY(-${hybridItem.flyDistance}px)`
                }
                break
            case '下':
                style = {
                    transform: `translateY(${hybridItem.flyDistance}px)`
                }
                break
        }
        style.transition = `${hybridItem.flyTime}s`
        return style
    }

    const flyIntervalAnimate = (elem, options, idx) => {
        setTimeout(() => {
            const { animateRate, animateType, scale, scrollDistance } = options
            let isImageAniamteEnter = true
            const setAnimate = () => {
                switch (animateType) {
                    case '放大缩小':
                        elem.style.transform = isImageAniamteEnter ? `scale(${scale})` : `scale(1)`
                        break
                    case '左右滑动':
                        elem.style.transform = isImageAniamteEnter ? `translateX(${scrollDistance}px)` : `translateX(-${scrollDistance}px)`
                        break
                    case '上下滑动':
                        elem.style.transform = isImageAniamteEnter ? `translateY(${scrollDistance}px)` : `translateY(-${scrollDistance}px)`
                        break
                }
                isImageAniamteEnter = !isImageAniamteEnter
            }
            setAnimate()
            timerGrp.current[idx] = setInterval(() => {
                setAnimate()
            }, animateRate * 1000)
        }, 100)

    }

    return (
        <>
            {
                hybridList.map((item, index) => {
                    return <div
                        id={flyImageId + '-' + index}
                        key={index}
                        style={Object.assign({
                            width: item.width,
                            height: item.height,
                            position: 'absolute',
                            left: item.left,
                            top: item.top,
                            zIndex,
                        }, initAnimateStyle(item))}
                        onTransitionEnd={() => {
                            if (isEnterAnimate.current === true) {
                                flyIntervalAnimate(allFlyImageList.current[index], item, index)
                            }
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                transition: `${item.animateRate}s`
                            }}
                        >
                            <img style={{
                                width: '100%',
                                height: '100%'
                            }} src={item.imageUrl} alt="" />
                            {
                                item.hotArea.map((hot, hotIndex) => {
                                    return <div
                                        key={hotIndex + 'hotIndex'}
                                        style={{
                                            width: hot.width,
                                            height: hot.height,
                                            position: 'absolute',
                                            left: hot.left,
                                            top: hot.top,
                                        }}
                                        onClick={() => {
                                            window.open(hot.jumpLink)
                                        }}
                                    >
                                        {
                                            hot.showPrice && (
                                                <div
                                                    style={{
                                                        color: hot.priceConfig.color,
                                                        fontSize: hot.priceConfig.fontSize,
                                                        fontWeight: hot.priceConfig.fontWeight,
                                                        position: 'absolute',
                                                        left: hot.priceConfig.left,
                                                        top: hot.priceConfig.top,
                                                    }}
                                                >{hot.priceConfig.showPrefiex ? '￥' : ''}{hot.priceConfig.useInputPrice ? hot.priceConfig.inputPrice : '100'}</div>
                                            )
                                        }

                                    </div>
                                })
                            }
                        </div>

                    </div>
                })
            }
        </>
    )
}