import Comp, { Dot } from './comp'
import Desc from './desc'
import Preview from './preview'

const Plugin = {
    name: '视频滑动',
    render: Comp,
    board: Desc,
    preview: Preview,
    subPlugins: [Dot],
    type: 'SlideVideo',
    props: {
        banners: [{
            mediaUrl: 'https://jvod.300hu.com/vod/product/e6c1cc3b-c948-4e6c-881c-11cd7add4e52/c01fed09c99a49f2ad5b5d7e4deeced0.mp4?source=2&h265=h265/18799/29eadccdd2204c8b92fc73fec387f7ae.mp4',
            jumpUrl: '//img30.360buyimg.com/zx/jfs/t1/100020/5/38609/44708/64096c1aF8ca20642/4e31ec08d9755c54.png',
            posterUrl: '//img20.360buyimg.com/zx/jfs/t1/229116/27/23847/391335/004dd101Fcf6e90f2/d8ab6747746758d2.gif',
            maskUrl: '//img12.360buyimg.com/zx/jfs/t1/79074/20/18365/635008/63b3e02eFd8150cef/964b3b5bece0ae09.png',
        },
        {
            mediaUrl: 'https://jvod.300hu.com/vod/product/e6c1cc3b-c948-4e6c-881c-11cd7add4e52/c01fed09c99a49f2ad5b5d7e4deeced0.mp4?source=2&h265=h265/18799/29eadccdd2204c8b92fc73fec387f7ae.mp4',
            jumpUrl: '//img30.360buyimg.com/zx/jfs/t1/100020/5/38609/44708/64096c1aF8ca20642/4e31ec08d9755c54.png',
            posterUrl: '//img20.360buyimg.com/zx/jfs/t1/229116/27/23847/391335/004dd101Fcf6e90f2/d8ab6747746758d2.gif',
            maskUrl: '//img12.360buyimg.com/zx/jfs/t1/79074/20/18365/635008/63b3e02eFd8150cef/964b3b5bece0ae09.png',
        },
        {
            mediaUrl: '//img12.360buyimg.com/zx/jfs/t1/208860/20/30225/43443/64096bf8Ff0a332ee/a9f2ea1685fd1607.png',
            jumpUrl: '//img30.360buyimg.com/zx/jfs/t1/100020/5/38609/44708/64096c1aF8ca20642/4e31ec08d9755c54.png',
            maskUrl: '//img12.360buyimg.com/zx/jfs/t1/79074/20/18365/635008/63b3e02eFd8150cef/964b3b5bece0ae09.png',
            posterUrl: '//img20.360buyimg.com/zx/jfs/t1/229116/27/23847/391335/004dd101Fcf6e90f2/d8ab6747746758d2.gif',
        }],
        imgWidth: 200,
        imgHeight: 400,
        slideWidth: 375,
        shrinkScale: 0.8,
        opacity: 0.8,
        interval: 3,
        subProps: [
            {
                showNumber: 0,
                defaultWidth: 30,
                activeWidth: 50,
                between: 5,
                radius: 4,
                defaultColor: '#000000',
                activeColor: '#ffffff',
            }
        ],
    },
    extension: {
        intervalDuration: [1, 10],
        slideWidthDuration: [0, 3000],
        struct: {
            mediaUrl: '',
            jumpUrl: '',
            posterUrl: '',
            maskUrl: '',
        },
    },
    rectOptions: {
        width: 200,
        height: 200,
        left: 0,
        top: 100,
        rotate: 0,
        subRectOptions: [
            {
                width: 200,
                height: 10,
                left: 120,
                top: 460,
                rotate: 0,
            },
        ]
    },
    drawOptions: {
        resize: false,
        drag: true,
        rotate: false,
        keepRatio: false,
        subOptions: [
            {
                resize: false,
                drag: true,
                rotate: false,
                keepRatio: false,
                // renderDirections: ['n', 's']
            }
        ]
    },
}

export default Plugin