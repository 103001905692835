import React, { useState } from 'react'
import { Space } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import '@/plugins/desc.less'
import {
    Input,
    Slider,
    Select,
    ColorPicker,
    ConfigTitle,
    BaseButton,
    Radio,
} from '@/components'
import { InputNumber3x, InputWithImg, HotArea } from '@/HOC'
import _ from 'lodash'

export default function Desc({
    currentPlugin,
    changeCurrentPluginRect,
    updateCurrentPluginProps,
    deleteInsertPlugin,
}) {
    const { props, rectOptions, extension } = currentPlugin
    const [currentIndex, setCurrentIndex] = useState(0)
    const curBanner = props.banners[currentIndex]
    const subProps = props.subProps[0]
    const subProps2 = props.subProps[1]
    const subRectOptions = rectOptions.subRectOptions[0]
    const subRectOptions2 = rectOptions.subRectOptions[1]
    return (
        <div
            className="desc-wrap"
        >
            <ConfigTitle
                style={{
                    padding: '20px 0 5px 20px'
                }}
                title="轮播组件"
                detail="自动循环播放视频或图片"
            />
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="轮播帧数"
                        detail="支持视频或图片的链接，图片可画热区"
                        style={{
                            height: 37,
                        }}
                        titleSize={14}
                        detailSize={12}
                    />
                    <Select
                        chooseList={props.banners.map((v, i) => `第${i + 1}帧`)}
                        onClick={(idx) => {
                            setCurrentIndex(idx)
                        }}
                        currentIndex={currentIndex}
                        onDrop={(newIndexGrp) => {
                            const newList = []
                            newIndexGrp.forEach((i) => {
                                newList.push(props.banners[i])
                            })
                            updateCurrentPluginProps({
                                banners: newList,
                            })
                        }}
                    />
                    <InputWithImg
                        labelContent="视频/图片链接"
                        defaultValue={curBanner.mediaUrl}
                        onChange={(e) => {
                            const list = [...props.banners]
                            list[currentIndex].mediaUrl = e
                            updateCurrentPluginProps({
                                banners: list,
                            })
                        }}
                    />
                    {
                        curBanner.mediaUrl && curBanner.mediaUrl.indexOf('.mp4') > -1 && <Input
                            labelContent="跳转链接"
                            defaultValue={curBanner.jumpUrl}
                            onChange={(e) => {
                                const list = [...props.banners]
                                list[currentIndex].jumpUrl = e
                                updateCurrentPluginProps({
                                    banners: list,
                                })
                            }}
                        />
                    }

                    {
                        curBanner.mediaUrl && curBanner.mediaUrl.indexOf('.mp4') > -1 && (
                            <InputWithImg
                                labelContent="蒙层图链接"
                                defaultValue={curBanner.mask}
                                onChange={(e) => {
                                    const list = [...props.banners]
                                    list[currentIndex].mask = e
                                    updateCurrentPluginProps({
                                        banners: list,
                                    })
                                }}
                            />
                        )
                    }
                    {
                        curBanner.mediaUrl && curBanner.mediaUrl.indexOf('.mp4') > -1 && (
                            <InputWithImg
                                labelContent="封面图链接"
                                defaultValue={curBanner.poster}
                                onChange={(e) => {
                                    const list = [...props.banners]
                                    list[currentIndex].poster = e
                                    updateCurrentPluginProps({
                                        banners: list,
                                    })
                                }}
                            />
                        )
                    }
                    {
                        curBanner.mediaUrl && curBanner.mediaUrl.indexOf('.mp4') < 0 && (
                            <HotArea
                                editContent={curBanner.mediaUrl}
                                defaultValue={curBanner.hotArea}
                                editContentSize={{
                                    width: rectOptions.width,
                                    height: rectOptions.height,
                                }}
                                contentFit="fill"
                                onSave={(data) => {
                                    const list = [...props.banners]
                                    list[currentIndex].hotArea = data
                                    updateCurrentPluginProps({
                                        banners: list
                                    })
                                }}
                                showCopyBtn={currentIndex !== 0}
                                onCopy={() => {
                                    const list = [...props.banners]
                                    list[currentIndex].hotArea = _.cloneDeep(list[0].hotArea, {})
                                    updateCurrentPluginProps({
                                        banners: list,
                                    })
                                }}
                            />
                        )
                    }

                    <Space>
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<DeleteFilled />}
                            content="删除本组"
                            onClick={() => {
                                const list = [...props.banners]
                                list.splice(currentIndex, 1)
                                if (list.length === 0) {
                                    deleteInsertPlugin(currentPlugin.insertId)
                                } else {
                                    setCurrentIndex(0)
                                    updateCurrentPluginProps({
                                        banners: list,
                                    })
                                }
                            }}
                        />
                        <BaseButton
                            style={{
                                width: 155
                            }}
                            type="normal"
                            icon={<PlusOutlined />}
                            content="添加帧数"
                            onClick={() => {
                                const list = [...props.banners]
                                list.push(Object.assign({}, extension.struct))
                                console.log(list, '111111')
                                updateCurrentPluginProps({
                                    banners: list
                                })
                            }}
                        />
                    </Space>

                </Space>
            </div>
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="轮播设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.width = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.height = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.top = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                rectOptions.left = val
                                changeCurrentPluginRect(rectOptions)
                            }}
                            defaultValue={rectOptions.left}
                        />
                    </Space>
                    <Slider
                        title="图片切换时间"
                        suffix="秒"
                        defaultValue={props.imageInterval}
                        max={extension?.imageIntervalDuration[1]}
                        min={extension?.imageIntervalDuration[0]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                imageInterval: v,
                            })
                        }}
                    />
                    <Slider
                        title="视频切换时间"
                        suffix="秒"
                        defaultValue={props.videoInterval}
                        max={extension?.videoIntervalDuration[1]}
                        min={extension?.videoIntervalDuration[0]}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                videoInterval: v,
                            })
                        }}
                    />
                </Space>
            </div>
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="进度点设置"
                        style={{
                            height: 21,
                        }}
                        titleSize={14}
                        detailSize={0}
                    />
                    <Radio
                        title="进度点类型"
                        radioList={[
                            '静态进度点',
                            '动态进度点'
                        ]}
                        defaultValue={subProps.type}
                        onChange={(val) => {
                            updateCurrentPluginProps({
                                type: val
                            }, 1)
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="未激活宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    defaultWidth: val
                                }, 1)
                            }}
                            defaultValue={subProps.defaultWidth}
                        />
                        <InputNumber3x
                            labelContent="激活宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    activeWidth: val
                                }, 1)
                            }}
                            defaultValue={subProps.activeWidth}
                        />
                    </Space>
                    <InputNumber3x
                        labelContent="高"
                        size="middle"
                        style={{
                            color: '#696969',
                        }}
                        onChange={(val) => {
                            subRectOptions.height = val
                            changeCurrentPluginRect(subRectOptions, 1)
                        }}
                        defaultValue={subRectOptions.height}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions.top = val
                                changeCurrentPluginRect(subRectOptions, 1)
                            }}
                            defaultValue={subRectOptions.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                subRectOptions.left = val
                                changeCurrentPluginRect(subRectOptions, 1)
                            }}
                            defaultValue={subRectOptions.left}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="间距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    between: val
                                }, 1)
                            }}
                            defaultValue={subProps.between}
                        />
                        <InputNumber3x
                            labelContent="圆角"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                updateCurrentPluginProps({
                                    radius: val
                                }, 1)
                            }}
                            defaultValue={subProps.radius}
                        />
                    </Space>
                    <ColorPicker
                        title="默认颜色"
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                defaultColor: v
                            }, 1)
                        }}
                        defaultValue={subProps.defaultColor}
                    />
                    <ColorPicker
                        title="激活颜色"
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                activeColor: v
                            }, 1)
                        }}
                        defaultValue={subProps.activeColor}
                    />
                    {/* tudo: 4.3bug文档问题说明存在俩个样式点选项要删掉下面这个，后续有需要再添加这块，先不完全删掉 */}
                    {/* <Radio
                        title="进度条动画"
                        defaultValue={props.type}
                        radioList={['默认', '填充']}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                type: v,
                            })
                        }}
                    /> */}
                </Space>
            </div>
            <div className="desc-board">
                <Space
                    direction="vertical"
                    size={10}
                >
                    <ConfigTitle
                        title="声音按钮设置（仅视频帧显示声音按钮）"
                        detail="视频默认无声音，点击可开启视频声音"
                        style={{
                            height: 'auto',
                        }}
                        titleSize={14}
                    />
                    <InputWithImg
                        labelContent="静音图片链接"
                        defaultValue={subProps2.mutedImgUrl}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                mutedImgUrl: v,
                            }, 2)
                        }}
                    />
                    <InputWithImg
                        labelContent="放声图片链接"
                        defaultValue={subProps2.unmutedImgUrl}
                        onChange={(v) => {
                            updateCurrentPluginProps({
                                unmutedImgUrl: v,
                            }, 2)
                        }}
                    />
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="宽"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    width: val
                                }, 2)
                            }}
                            defaultValue={subRectOptions2.width}
                        />
                        <InputNumber3x
                            labelContent="高"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    height: val
                                }, 2)
                            }}
                            defaultValue={subRectOptions2.height}
                        />
                    </Space>
                    <Space size={10}>
                        <InputNumber3x
                            labelContent="上边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    top: val
                                }, 2)
                            }}
                            defaultValue={subRectOptions2.top}
                        />
                        <InputNumber3x
                            labelContent="左边距"
                            size="middle"
                            style={{
                                color: '#696969',
                            }}
                            onChange={(val) => {
                                changeCurrentPluginRect({
                                    left: val
                                }, 2)
                            }}
                            defaultValue={subRectOptions2.left}
                        />
                    </Space>
                </Space>
            </div>
        </div>
    )
}